import React from 'react';
import { useSelector, useStore } from 'react-redux';

export const useSearchCriteria = () => {
  const { bed_from, bed_to, radius, size_from, size_to, sale_date_limit_to, sale_date_limit_from } =
    useSelector(({ search }) => search?.api ?? {});

  return React.useMemo(
    () => ({
      distance: radius,
      bedFrom: bed_from,
      bedTo: bed_to,
      sizeFrom: size_from,
      sizeTo: size_to,
      saleDateLimitFrom: sale_date_limit_from,
      saleDateLimitTo: sale_date_limit_to,
    }),
    [bed_from, bed_to, radius, size_from, size_to, sale_date_limit_to],
  );
};

export const useAllSearchCriteria = () => useSelector(({ search }) => search?.searchCriteria ?? {});

export const useAllSearchCriteriaGetter = () => {
  const store = useStore();

  return () => store.getState()?.search?.searchCriteria || {};
};

export const useGetExclusions = () =>
  useSelector(({ report }) => {
    const rebuildData = report?.rebuildData ?? {};
    if (!rebuildData?.request?.excluded_ids) {
      return { excluded_ids: [] };
    }

    return {
      excluded_ids: rebuildData.request.excluded_ids,
    };
  });
