import React, { useRef } from 'react';
import styled from 'styled-components';

import { HomesGroup } from './HomesGroup/HomesGroup';
import withHeaderFont from '../../hoc/withHeaderFont';
import { ShowPropertiesOnMap } from './ShowPropertiesOnMap/ShowPropertiesOnMap';
import { useProperties } from '../../../../../../../../providers/providers/PropertiesProvider';

const TotalResultContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ededed;
  color: #373e43;
  text-transform: uppercase;
  padding: 0 10px;
  font-size: 13px;
`;

const TotalResultLabel = withHeaderFont(styled.div`
  margin: 0;
  font-weight: bold;
  line-height: 31.88px;
`);

const Container = styled.section`
  padding: 0 20px 20px 0;
  height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  max-height: 754px;
`;

export const PropertiesList: React.FC = () => {
  const { properties } = useProperties();

  const ref = useRef<HTMLElement>(null);

  return (
    <Container ref={ref}>
      <TotalResultContainer>
        <TotalResultLabel>{properties?.length} TOTAL RESULTS</TotalResultLabel>
        <ShowPropertiesOnMap />
      </TotalResultContainer>

      <HomesGroup properties={properties} propertiesRef={ref} />
    </Container>
  );
};
