import styled from 'styled-components';
import { TProperty } from '../../../../types';
import { SectionTitle } from './components/SectionTitle';
import { SectionDetailItem } from './components/SectionDetailItem';
import { CurrentUserService } from '../../../../services/CurrentUserService';
import { getPropertyTypeText } from '../../../../pages/PresentationCreate/dash/view/services/getPropertyTypeText';
import { NumberFormatter } from '../../../../pages/PresentationCreate/dash/services/numberFormatter';
import { MoneyFormatter } from '../../../../pages/PresentationCreate/dash/services/moneyFormatter';

interface Props {
  property: TProperty;
}

const getPropertyDistance = (property: TProperty) =>
  CurrentUserService.isCanadaUser()
    ? `${property?.distanceKm} km away`
    : `${property?.distance} mi away`;

export const ComparableDetails = ({ property }: Props) => {
  return (
    <Container>
      <SectionTitle title='Comparable details' />
      <SectionDetailItem label='Distance' value={getPropertyDistance(property)} />
      <SectionDetailItem label='Comp Type' value={getPropertyTypeText(property.status)} />
      <SectionDetailItem label='Year' value={String(property.yearBuilt)} />
      {property.totalSize && (
        <SectionDetailItem
          label='Full Lot Sqft'
          value={NumberFormatter.format(property.totalSize)}
        />
      )}
      {property.hoaFee && (
        <SectionDetailItem
          label='Association Fees'
          value={`${MoneyFormatter.format(property.hoaFeeValue)} (${property.hoaFeeFreq})`}
        />
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;
