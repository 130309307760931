import React, { ReactNode } from 'react';

import type { TListingFeedback } from './components/Slide/slides/buyerTour/listingFeedback';
import type { TCoverSlide } from './components/Slide/slides/startingSlides/Cover';
import type { TGreetingVideo } from './components/Slide/slides/startingSlides/GreetingVideo';
import type { TCmaReport } from './components/Slide/slides/whatsMyHomeWorth/cmaReport';
import type { TClosingAndNextSteps } from './components/Slide/slides/closingAndNextSteps/closingAndNextSteps';
import type { TOneImageLayoutAlpha } from './components/Slide/dynamic/templates/oneImageLayout/alpha';
import type { TTwoImageLayoutAlpha } from './components/Slide/dynamic/templates/twoImagesLayout/alpha';
import type { TNetProceeds } from './components/Slide/slides/closingAndNextSteps/netProceeds';
import type { TValueOfAgent } from './components/Slide/slides/whyIAmTheRightFitSection/valueOfAnAgent';
import type { TRealtorVSIBuyer } from './components/Slide/slides/whyIAmTheRightFitSection/realtorVSIBuyer';
import type { TPropertyBoost } from './components/Slide/slides/marketingAndBuyerActivitySection/propertyBoost';
import type { TPricingMisconceptions } from './components/Slide/slides/whatsMyHomeWorth/PricingMisconceptions';
import type { THomeBuyingProcess } from './components/Slide/slides/whyIAmTheRightFitSection/homeBuyingProcess';
import type { TBuyerInterest } from './components/Slide/slides/marketingAndBuyerActivitySection/BuyerInterest';
import type { THomeSellingProcess } from './components/Slide/slides/whyIAmTheRightFitSection/homeSellingProcess';
import type { TWhereBuyersGetTheirInfo } from './components/Slide/slides/whatsMyHomeWorth/whereBuyersGetTheirInfo';
import type { TMyMarketingPlan } from './components/Slide/slides/marketingAndBuyerActivitySection/MyMarketingPlan';
import type { TImportanceOfOpeningWeekend } from './components/Slide/slides/marketAnalysis/importanceOfOpeningWeekend';
import type { TAboutMe } from './components/Slide/slides/whyIAmTheRightFitSection/personalSlides/slides/AboutMe';
import type { TComparativeMarketAnalysis } from './components/Slide/slides/whatsMyHomeWorth/comparativeMarketAnalysis';
import type { TMyTestimonials } from './components/Slide/slides/whyIAmTheRightFitSection/personalSlides/slides/MyTestimonials';
import type { TWhatBuyersWantFromTheirAgents } from './components/Slide/slides/whyIAmTheRightFitSection/whatBuyersWantFromTheirAgents';
import type { TImportanceOfStaging } from './components/Slide/slides/marketingAndBuyerActivitySection/ImportanceOfStaging';
import type { TWhyPricingCorrectlyIsImportant } from './components/Slide/slides/marketAnalysis/whyPricingCorrectlyIsImportant';
import type { TSectionContents } from './components/Slide/slides/sectionContents';
import type { TBuyerDemand } from './components/Slide/slides/marketingAndBuyerActivitySection/buyerDemand';
import type { TThreeImagesLayoutAlpha } from './components/Slide/dynamic/templates/threeImagesLayout/alpha';
import type { TTextLayoutAlpha } from './components/Slide/dynamic/templates/textLayout/alpha';
import type { TFourImageLayoutAlpha } from './components/Slide/dynamic/templates/fourImagesLayout/alpha';
import type { TOneImageLayoutGamma } from './components/Slide/dynamic/templates/oneImageLayout/gamma';
import type { TOneImageLayoutBeta } from './components/Slide/dynamic/templates/oneImageLayout/beta';
import type { TOneImageLayoutDelta } from './components/Slide/dynamic/templates/oneImageLayout/delta';
import type { TOneImageLayoutEpsilon } from './components/Slide/dynamic/templates/oneImageLayout/epsilon';
import type { TOneImageLayoutZeta } from './components/Slide/dynamic/templates/oneImageLayout/zeta';
import type { TTwoImagesLayoutBeta } from './components/Slide/dynamic/templates/twoImagesLayout/beta';
import type { TTwoImagesLayoutGamma } from './components/Slide/dynamic/templates/twoImagesLayout/gamma';
import type { TTwoImagesLayoutDelta } from './components/Slide/dynamic/templates/twoImagesLayout/delta';
import type { TTwoImagesLayoutEpsilon } from './components/Slide/dynamic/templates/twoImagesLayout/epsilon';
import type { TTwoImagesLayoutZeta } from './components/Slide/dynamic/templates/twoImagesLayout/zeta';
import type { TThreeImagesLayoutBeta } from './components/Slide/dynamic/templates/threeImagesLayout/beta';
import type { TThreeImagesLayoutGamma } from './components/Slide/dynamic/templates/threeImagesLayout/gamma';
import type { TThreeImagesLayoutDelta } from './components/Slide/dynamic/templates/threeImagesLayout/delta';
import type { TThreeImagesLayoutEpsilon } from './components/Slide/dynamic/templates/threeImagesLayout/epsilon';
import type { TThreeImagesLayoutZeta } from './components/Slide/dynamic/templates/threeImagesLayout/zeta';
import type { TFourImagesLayoutBeta } from './components/Slide/dynamic/templates/fourImagesLayout/beta';
import type { TFourImagesLayoutGamma } from './components/Slide/dynamic/templates/fourImagesLayout/gamma';
import type { TFourImagesLayoutDelta } from './components/Slide/dynamic/templates/fourImagesLayout/delta';
import type { TFourImagesLayoutEpsilon } from './components/Slide/dynamic/templates/fourImagesLayout/epsilon';
import type { TFourImagesLayoutZeta } from './components/Slide/dynamic/templates/fourImagesLayout/zeta';
import type { TTextLayoutBeta } from './components/Slide/dynamic/templates/textLayout/beta';
import type { TTextLayoutGamma } from './components/Slide/dynamic/templates/textLayout/gamma';
import type { TTextLayoutDelta } from './components/Slide/dynamic/templates/textLayout/delta';
import type { TTextLayoutEpsilon } from './components/Slide/dynamic/templates/textLayout/epsilon';
import type { TTextLayoutZeta } from './components/Slide/dynamic/templates/textLayout/zeta';
import type { TSlideThemeLuxury } from './components/Slide/components/SlideFrame/frames/web/Luxury';
import type { TSlideThemeModern } from './components/Slide/components/SlideFrame/frames/web/Modern';
import type { TSlideThemeTraditional } from './components/Slide/components/SlideFrame/frames/web/Traditional';
import type { TSingleImageTemplate } from './components/Slide/dynamic/templates/singleImageLayout/singleImage';
import type { TSingleImageWithFrameTemplate } from './components/Slide/dynamic/templates/singleImageLayout/singleImageWithFrame';
import type { TMarketOverview } from './components/Slide/slides/marketAnalysis/marketOverview';
import type { TWhatFactorsInfluencePricing } from './components/Slide/slides/marketAnalysis/whatFactorsInfluencePricing';
import type { TSingleFramelessVideoTemplate } from './components/Slide/dynamic/templates/singleVideoLayout/singleFramelessVideo';
import type { TSingleVideoTemplate } from './components/Slide/dynamic/templates/singleVideoLayout/singleVideo';
import type { TPdfPresentationTemplate } from './components/Slide/dynamic/templates/pdfPresentationLayout';
import type { TListingDetails } from './components/Slide/slides/buyerTour/listingDetails';

import { BUYER_TOUR } from './features/BuyerTour/constants';
import { TCoreSocial } from './components/Slide/slides/marketingAndBuyerActivitySection/CoreSocial';
import { TOneSheeter } from './components/Slide/slides/whyIAmTheRightFitSection/oneSheeter';
import { WIN_THE_REPRESENTATION } from './features/WinTheRepresentation/constants';
import { TWhyIAmTheRightBuyersAgent } from './components/Slide/slides/whyIAmTheRightFitSection/whyIAmTheRightBuyersAgent';
import { TPurposeOfBuyersAgent } from './components/Slide/slides/marketingAndBuyerActivitySection/purposeOfBuyersAgent';
import { TBuyersAgentCompensation } from './components/Slide/slides/marketingAndBuyerActivitySection/buyersAgentCompensation';

export type TSlideTheme = TSlideThemeTraditional | TSlideThemeModern | TSlideThemeLuxury;

export type TFrameConfig = {
  id: TSlideTheme;
  thumbnail: string;
  Frame: React.FC<CommonFrameProps>;
};

// export type TSlideInfoPlacement = 'top' | 'bottom';

export type TSection =
  | 'startingSlides'
  | 'whyIAmTheRightFit'
  | 'marketingAndBuyerActivity'
  | 'closingAndNextSteps'
  | 'whatsMyHomeWorth'
  | 'marketAnalysis';

export type TPersonalizeSlide = TAboutMe | TMyTestimonials;

type TMarketAnalysis =
  | TMarketOverview
  | TWhatFactorsInfluencePricing
  | TWhyPricingCorrectlyIsImportant
  | TImportanceOfOpeningWeekend;

type TMarketingAndBuyerActivitySlides =
  | TImportanceOfStaging
  | TBuyerInterest
  | THomeBuyingProcess
  | THomeSellingProcess
  | TWhatBuyersWantFromTheirAgents
  | TNetProceeds
  | TBuyerDemand
  | TCoreSocial;

export type TWhatsMyHomeWorth =
  | TComparativeMarketAnalysis
  | TWhereBuyersGetTheirInfo
  | TPricingMisconceptions;

export type TSlideId =
  | TGreetingVideo
  | TCoverSlide
  | TCmaReport
  | TClosingAndNextSteps
  | TValueOfAgent
  | TMarketingAndBuyerActivitySlides
  | TPersonalizeSlide
  | TWhatsMyHomeWorth
  | TRealtorVSIBuyer
  | TMyMarketingPlan
  | TPropertyBoost
  | TMarketAnalysis
  | TSectionContents
  | TListingFeedback
  | TListingDetails
  | TOneSheeter
  | TWhyIAmTheRightBuyersAgent
  | TPurposeOfBuyersAgent
  | TBuyersAgentCompensation;

export type TDynamicSlideOneImageTemplate =
  | TOneImageLayoutAlpha
  | TOneImageLayoutBeta
  | TOneImageLayoutGamma
  | TOneImageLayoutDelta
  | TOneImageLayoutEpsilon
  | TOneImageLayoutZeta;

export type TDynamicSlideTwoImagesTemplate =
  | TTwoImageLayoutAlpha
  | TTwoImagesLayoutBeta
  | TTwoImagesLayoutGamma
  | TTwoImagesLayoutDelta
  | TTwoImagesLayoutEpsilon
  | TTwoImagesLayoutZeta;

export type TDynamicSlideThreeImagesTemplate =
  | TThreeImagesLayoutAlpha
  | TThreeImagesLayoutBeta
  | TThreeImagesLayoutGamma
  | TThreeImagesLayoutDelta
  | TThreeImagesLayoutEpsilon
  | TThreeImagesLayoutZeta;

export type TDynamicSlideFourImagesTemplate =
  | TFourImageLayoutAlpha
  | TFourImagesLayoutBeta
  | TFourImagesLayoutGamma
  | TFourImagesLayoutDelta
  | TFourImagesLayoutEpsilon
  | TFourImagesLayoutZeta;

export type TDynamicSlideTextTemplate =
  | TTextLayoutAlpha
  | TTextLayoutBeta
  | TTextLayoutGamma
  | TTextLayoutDelta
  | TTextLayoutEpsilon
  | TTextLayoutZeta;

export type TDynamicSlideVideoTemplate = TSingleFramelessVideoTemplate | TSingleVideoTemplate;

export type TDynamicSlideTemplate =
  | TDynamicSlideTwoImagesTemplate
  | TDynamicSlideOneImageTemplate
  | TDynamicSlideThreeImagesTemplate
  | TDynamicSlideFourImagesTemplate
  | TDynamicSlideTextTemplate
  | TDynamicSlideVideoTemplate
  | TSingleImageTemplate
  | TSingleImageWithFrameTemplate
  | TPdfPresentationTemplate;

export type TPersonalSlideExperienceLevel = 'none' | 'novice' | 'experienced' | 'seasoned';

export type TPersonalSlideExperienceDefaultText = Record<TPersonalSlideExperienceLevel, string>;

export type TEmphasis =
  | 'defaultOrder'
  | 'rightTimeToSell'
  | 'agentIsTheRightFit'
  | 'topDollarValue';

export interface ITestimonialLink {
  title: string;
  url: string;
}

export interface ITestimonial {
  id: string;
  text: string;
  client: string;
  isHidden: boolean;
}

export interface IUserApiTestimonial {
  testimonial: string;
  who: string;
}

export interface IMapLatLng {
  lat: number;
  lng: number;
}
export type TNumberOfBuyersLookingForHomes = 'very_low' | 'low' | 'medium' | 'high' | 'very_high';

export interface IZipData {
  coordinates: any;
  zip: any;
  opacity: TNumberOfBuyersLookingForHomes;
}

export interface IMapData {
  activeComparables: IMapLatLng[];
  activeNonComparables: IMapLatLng[];
  center: IMapLatLng;
}

export type TSlideData = {
  headline?: string;
  headline2?: string;
  headline3?: string;
  text?: string;
  text2?: string;
  text3?: string;
  image?: string;
  image2?: string;
  image3?: string;
  image4?: string;
  displayImage?: boolean;
  experienceLevel?: TPersonalSlideExperienceLevel;
  experienceDefaultText?: TPersonalSlideExperienceDefaultText;
  testimonials?: ITestimonial[];
  links?: ITestimonialLink[];
  sellerSupplyOn?: boolean;
  featuresOfInterestOn?: boolean;
  mapData?: IMapData;
  videoUrl?: string;
  pdf?: string;
  webPdf?: string;
  cmaReportShowAllProperties?: boolean;
};

export type TTag = {
  id: string;
  label: string;
};

export type TSlidesConfigMap = Record<TSlideId | string, TSlide>;
export type TBuyerDemandInterestData = { interest: any; demand: any };

export type TSlide = {
  id: TSlideId | string;
  template?: TDynamicSlideTemplate;
  label: string;
  boldTrailLabel?: string;
  excludeFromConfig?: boolean;
  includeSlide: boolean;
  frame?: boolean;
  data?: TSlideData;
  isInteractive?: boolean;
  netProceedsData?: TNetProceedsData[];
  isEditable?: boolean;
  entityId?: number | null;
  tags?: TTag[];
  buyerInterestData?: any | null;
  buyerDemandData?: {
    zipData: IZipData[] | null;
    properties: any[];
  };
  sectionId?: TSection;
};

export type TSlideLockableValue = {
  value: string;
  locked?: boolean;
  lockedStatus?: Record<string, boolean | undefined>;
  valueData?: Record<string, string | undefined>;
};

export type TSlideColors = {
  primary: TSlideLockableValue;
  secondary?: TSlideLockableValue;
};

export type TSlideFonts = {
  header: TSlideLockableValue;
  body: TSlideLockableValue;
};

export type TSlideLogos = {
  first?: TSlideLockableValue;
  second?: TSlideLockableValue;
  third?: TSlideLockableValue;
};

export interface TSlideInfoPlacement extends TSlideLockableValue {
  value: 'top' | 'bottom';
}

export interface CommonFrameProps {
  infoPlacement?: TSlideInfoPlacement['value'];
  hideProgressTracker?: boolean;
}

export interface SlideContentFactoryProps extends CommonFrameProps {
  slide: any;
  theme: TSlideTheme;
  isEditable?: boolean;
  user?: TUser;
  isDefaultConfig?: boolean;
  platform?: TSlidePlatform;
  activeSection?: TSection | TDynamicSectionId;
  presentationHash?: string;
  onCursorTracking?: (e: any) => void;
  showTemplatePreview?: boolean;
  whiteLabelSettings?: TWhiteLabelSlidesProp | null;
}

export type TPresentationUiConfig = {
  useKvCoreLogo: boolean;
  logos: TSlideLogos;
  infoPlacement: TSlideInfoPlacement;
  colors: TSlideColors;
  theme: TSlideTheme;
  fonts: TSlideFonts;
};

export type TSectionConfig = {
  id: TSection;
  label: string;
  slides: TSlide[];
};

export type TPresentationSlides = {
  sections: Record<TSection, TSectionConfig>;
  orderById: TSection[];
};

export type TPresentationType =
  | 'winTheListing'
  | 'winTheOffer'
  | 'presentInfo'
  | TPresentationTypeBuyerTour
  | TPresentationTypeWTR;

export type TPresentationMode = 'regular' | 'cma' | 'oneSheeter';

export type TSuperPresentation = TPresentation | TBuyerTourPresentation;

export type TPresentationModeConfig = {
  order: TDynamicOrder;
  sections: Record<TDynamicSectionId, TDynamicSection>;
};

export enum InheritanceLevel {
  AGENT = 'agent',
  TEAM = 'team',
  OFFICE = 'office',
  ACCOUNT = 'account',
  SUPERACCOUNT = 'superaccount',
}

export type TPresentationMeta = {
  inheritanceLevel: InheritanceLevel;
};

export type TPresentation = {
  category: TCategory;
  ui: TPresentationUiConfig;
  slides?: TPresentationSlides;
  reportData?: any;
  type?: TPresentationType;
  user?: TUser;
  hash?: string;
  agreementLink?: string;
  videoLink?: string;
  clientId?: string;
  clientName?: string;
  clientEmail?: string;
  id?: string;
  mode?: TPresentationMode;
  cma?: TPresentationModeConfig;
  oneSheeter?: TPresentationModeConfig;
  regular?: TPresentationModeConfig;
  slidesMap?: Record<TSlideId | string, TSlide>;
  createdAt?: number;
  meta?: TPresentationMeta;
  properties?: TProperty[];
  comparableProperties?: PropertyGroups;
  version?: number;
  whiteLabelSettings?: TWhiteLabelSlidesProp | null;
  graphs?: ReportGraphs;
};

export type TOrderByType = Partial<Record<TPresentationType, TFullOrder>>;

export type TUserConfigsCategory = {
  category: TCategory;
  ui: TUserConfigsUi;
  slides?: TPresentationSlides;
  order?: TFullOrder;
  orderByType?: TOrderByType;
};

export type TUserConfigsUi = {
  useKvCoreLogo?: boolean;
  logos?: TSlideLogos;
  infoPlacement?: TSlideInfoPlacement;
  colors?: TSlideColors;
  theme?: TSlideTheme;
  fonts?: TSlideFonts;
};

export type TCategory = 'traditional' | 'modern' | 'luxury';
export type TUserConfigs = {
  [TC in TCategory]: TUserConfigsCategory;
};

export type TInheritanceUserConfigs = {
  level: InheritanceLevel;
  data: TUserConfigs;
};

export type TSlidePlatform = 'web' | 'pdf';

export type TKvCoreEntity = {
  id: number;
  name: string;
  isAdmin: boolean;
  type: TEntityType;
};

export type TEntityType = 'account' | 'superaccount' | 'office' | 'team';

export type TUser = {
  userId: string;
  name: string;
  position: string;
  email: string;
  phone: string;
  image: string;
  slideProfileImage?: string;
  website: string;
  cal_bre: string;
  is_canada_user: boolean;
  company: string;
  address: string;
  city: string;
  zip: string;
  hash: string;
  teams: TKvCoreEntity[];
  offices: TKvCoreEntity[];
  account: TKvCoreEntity;
  superaccounts: TKvCoreEntity[];
  state: string;
  primaryEntity: number;
  bio: string;
  testimonials: ITestimonial[];
  accountId: number;
  companyLogo: string;
  companyColor: string;
  useSpecialBranding: boolean;
  kvcoreParentAccountId: number | null;
  kvcoreUserId: number | null;
  shouldHideIntercomButton: boolean;
  signature?: string;
  showSignatureOnOutgoingEmails?: boolean;
  teamLogo?: string;
  teamsPrimaryEntity: number;
  officePrimaryEntity: number;
};

export type TApiUser = {
  address: string;
  address_2: string | null;
  avatar: string;
  bio: string;
  city: string;
  company: string;
  company_color: string;
  company_logo: string;
  email: string;
  entities: string;
  feed_ids: string[];
  first_name: string;
  hash: string;
  id: number;
  is_canada_user: boolean;
  kvcore_account_id: number;
  kvcore_parent_account_id: number | null;
  kvcore_primary_entity: number | null;
  kvcore_user_id: number;
  last_name: string;
  license: string;
  phone: string;
  state: string;
  team_logo: string | null;
  testimonials: string;
  title: string;
  use_special_branding: number;
  website: string;
  zip: string;
  external_vendor_id: string;
  should_hide_intercom_button: boolean;
};

type TListPrice = {
  price: number;
};

type TMortgagePayoffs = {
  first: number;
};

type TRealEstateFees = {
  listingOffice: number;
  sellingOffice: number;
};

type TSettlementCosts = {
  escrowFee: number;
  titleInsurance: number;
  proratedPropertyTax: number;
};

type TTransferExciseTax = {
  stateTax: number;
};

type TOtherCosts = {
  assessments: number;
  HOAFees: number;
  administrativeFee: number;
  homeWarrantyPolicy: number;
  inspections: number;
  closingCosts: number;
};

export type TNetProceedsData = {
  listPrice: TListPrice;
  mortgagePayoffs: TMortgagePayoffs;
  realEstateFees: TRealEstateFees;
  settlementCosts: TSettlementCosts;
  transferExciseTax: TTransferExciseTax;
  otherCosts: TOtherCosts;
};

export type TPresentationTypeBuyerTour = typeof BUYER_TOUR;

export type TDynamicSectionId = string;

export type TDynamicSection = {
  id: TDynamicSectionId;
  label: string;
};

export type TSectionsSlide = Record<TDynamicSectionId, TSlideId[]>;
export type TDynamicOrder = {
  sections: TDynamicSectionId[];
  sectionsSlide: TSectionsSlide;
};
export type TFullOrder = {
  sections: TDynamicSectionId[];
  sectionsSlide: Record<TDynamicSectionId, Partial<TSlide>[]>;
};

export type TBuyerTourSlideId = TCoverSlide | TGreetingVideo;
export type TCoordinate = {
  latitude: number;
  longitude: number;
};

export type TAddress = {
  deliveryLine: string;
  city: string;
  state: string;
  zip: string;
};

type TPropertySource = 'manual' | 'mls';

export type ComparableExtension = {
  id: string;
  name: string;
  value: number;
};

export type TProperty = {
  id: string;
  description: string;
  source?: TPropertySource;
  excluded?: boolean;
  mlsId: number;
  mlsListingId: string;
  propertyType: string;
  address: TAddress;
  size: number;
  sizeDiff: number;
  totalSize: number;
  beds: number;
  totalBaths: number;
  baths: number;
  daysOnMarket: number;
  daysToClose: number;
  price: number;
  pricePerSqft: number;
  salePrice: number;
  salePricePerSqft: number;
  yearBuilt: number;
  coordinates: TCoordinate;
  distance: number;
  distanceKm: number;
  pool: boolean;
  view: boolean;
  status: PROPERTY_STATUS | 'coming_soon';
  images: string[];
  listDate: number;
  listDateHuman: string;
  saleDate: number;
  saleDateHuman: string;
  partialMatch: boolean;
  partialMatchInfo: string;
  brokerName: string;
  basement: string;
  isWaterFront: boolean;
  isWaterView: boolean;
  isDeck: boolean;
  isFixerUpper: boolean;
  isNewConstruction: boolean;
  isAdult55: boolean;
  isGreenEnergyStar: boolean;
  isHorseProperty: boolean;
  isGolfCourse: boolean;
  isAirConditioning: boolean;
  isMasterOnMain: boolean;
  hoaFee: boolean;
  hoaFeeValue: number | null;
  hoaFeeFreq: string | null;
  garage: number;
  levels: number;
  saleType: null;
  disclaimer: string;
  singleImage: null;
  virtualTour: string;
  comparableExtensions?: ComparableExtension[];
  adjustedPrice?: number;
  note?: string;
  adjustedPricePerSqft?: number;
};

export type TClient = {
  email?: string;
  id?: number | string;
  name?: string;
};

interface TPresentationBase {
  type: TPresentationType;
  id?: string;
  hash?: string;
  user?: TUser;
  category: TCategory;
  ui: TPresentationUiConfig;
}

export interface TBuyerTourPresentation extends TPresentationBase {
  properties: TProperty[];
  slides: Record<TSlideId, TSlide>;
  sections: Record<TDynamicSectionId, TDynamicSection>;
  order: TDynamicOrder;
  clientEmail: string;
  clientId: string;
  clientName: string;
  createdAt: number;
  videoLink?: string;
  whiteLabelSettings?: TWhiteLabelSlidesProp | null;
  graphs?: ReportGraphs;
}

export interface IPresentationConfigs {
  getSlides(): Partial<Record<TSlideId, TSlide>>;
  getSections(): Record<TDynamicSectionId, TDynamicSection>;
  getFullOrder(): TDynamicOrder;
  getDefaultOrder(): TDynamicOrder;
  getPredefinedSectionSlides(): Record<TDynamicSectionId, TSlideId[]>;
}

export type TPresentationSessionSlide = {
  id: TSlideId;
  name: string;
  duration: number;
  data: THeatmapTracking;
  views: number;
  interactions: number;
};
export interface IPresentationSlideAnalytics {
  slideId: string;
  timeSpent: number | null;
  views: number | null;
  interactions: number | null;
  cursorTrackingData: THeatmapData[];
}
export interface IPresentationAnalytics {
  averageTimeSpent: number | null;
  lastOpened: string | null;
  slides: Record<string, TPresentationSessionSlide>;
}

export type THeatmapData = {
  x: number;
  y: number;
  value: number;
};

export type THeatmapTracking = {
  clicks?: THeatmapData[];
  movements: THeatmapData[];
};

export type TAddSlideBtnStyle = 'option' | 'add';

export type TPastPresentationRaw = {
  id: number;
  hash: string;
  createdAt: string;
  data: TPresentation;
  build_in_progress: boolean;
  created_at_timestamp: number;
  updated_at_timestamp: number;
  pdf: string;
};

export enum EntityMode {
  READ = 'read',
  WRITE = 'write',
}

export enum FEEDBACK_RESPONSE {
  NEED_MORE_TIME = 'Need more time',
  LETS_GET_STARTED = "Let's get started",
}

export enum MORE_TIME_DESCRIPTIONS {
  HOPING_TO_LIST_FOR_MORE = 'Hoping to list for more',
  HOPING_TO_BUY_FOR_LESS = 'Hoping to buy for less',
  COMPETITIVE_MARKET = 'Competitive market',
  TIME_IT_WILL_TAKE_TO_SELL = 'Time it will take to sell',
  TIME_IT_WILL_TAKE_TO_BUY = 'Time it will take to buy',
  MEETING_WITH_OTHER_AGENTS = 'Meeting with other agents',
  OTHER = 'Other',
}
export interface FeatureFlag {
  enabledInd: boolean;
  feature: string;
}

export type TPresentationTypeWTR = typeof WIN_THE_REPRESENTATION;

export type TooltipPayloadProps = {
  dataKey: string;
  name: string;
  payload: {
    id: string;
    days_on_market: number;
    days_to_close: number;
    name: string;
    sale_date: string;
    sale_price: number;
    price: number;
    listDateHuman: string;
    address: TAddress;
    status: string;
    size: number;
    pricePerSqft: number;
    adjustedPrice?: number;
    adjusted_count?: number;
    adjusted_price?: number;
    adjusted_sale_count?: number;
    adjusted_sale_price?: number;
  };
  type?: string;
  unit: string;
  value: number;
  color?: string;
};

export type PresentationNavItem = {
  label: string;
  isActive?: boolean;
};

export enum PROPERTY_STATUS {
  ACTIVE = 'active',
  PENDING = 'pending',
  SOLD = 'sold',
  COMING_SOON = 'comingSoon',
}

export type Subject = IMapLatLng & {
  address: string;
  property: TProperty;
};

export type ReportProperty = IMapLatLng & {
  address: string;
  excluded?: boolean;
  status: PROPERTY_STATUS | 'coming_soon';
  property: TProperty;
};

export type RawReport = {
  subject: TProperty;
  properties: {
    active: TProperty[];
    pending: TProperty[];
    sold: TProperty[];
    comingSoon: TProperty[];
  };
  graphs: ReportGraphs;
  useDtcInsteadDomForSold?: boolean;
  compAverages: CompAverages;
  estimates: ReportEstimates;
  flags: ReportFlags;
};

export type ReportGraphs = {
  pricing_favorability: {
    description: string;
    graph: RawPricingFavorabilityGraph[];
    text: string;
  };
  rate_of_change: {
    props: RawPricingRateOfChangeProps[];
    text: string;
  };
  active_shelf_life: {
    text: number;
    graph: RawActiveShelfLifeGraph[];
  };
  selling_pace: {
    average: number;
    average_of_all: number;
    median_days_to_close_of_all: number;
    median_of_all: number;
    text: string;
    graph: RawSellingPaceGraph[];
  };
  avg_days_to_sell: any; // TODO: Add type
  competition: TCompetition;
};

export type RawPricingFavorabilityGraph = {
  count: number;
  days_on_market: number;
  favorability: {
    description: string;
    value: string;
  };
  month: string;
  price: number;
  sale_count: number;
  sale_price: number;
  adjusted_count: number;
  adjusted_price: number;
  adjusted_sale_count: number;
  adjusted_sale_price: number;
};

export type TPricingFavorabilityGraph = {
  value: number;
  fav: {
    description: string;
    value: string;
  };
  label: string;
  count: number;
  price: number;
  sale_count: number;
  sale_price: number;
  adjusted_count: number;
  adjusted_price: number;
  adjusted_sale_count: number;
  adjusted_sale_price: number;
};

export type TPricingFavorability = {
  text: string;
  description: string;
  graph: TPricingFavorabilityGraph[];
};

export type RawPricingRateOfChangeProps = {
  address: string;
  id: string;
  list_price: number;
  sale_date: string;
  sale_price: number;
  adjusted_sale_price: number;
};

export type TPricingRateOfChangeProps = {
  name: string;
  id: string;
  list_price: number;
  sale_date: string;
  sale_price: number;
  adjusted_sale_price: number;
};

export type TPricingRateOfChange = {
  text: string;
  props: TPricingRateOfChangeProps[];
};

export type RawSellingPaceGraph = {
  id: string;
  address: string;
  days_on_market: number;
  days_to_close: number;
  sale_date: string;
  sale_price: number;
};

export type TSellingPaceGraph = {
  id: string;
  days_on_market: number;
  days_to_close: number;
  name: string;
  sale_date: string;
  sale_price: number;
};

export type TSellingPace = {
  average: number;
  average_of_all: number;
  median_days_to_close_of_all: number;
  median_of_all: number;
  text: string;
  graph: TSellingPaceGraph[];
};

export type RawActiveShelfLifeGraph = {
  count: number;
  days_on_market: number;
  days_on_market_highest: number;
  days_on_market_lowest: number;
  days_on_market_median: number;
  name: string;
};

export type TActiveShelfLife = {
  text: number;
  graph: RawActiveShelfLifeGraph[];
};

export type TCompetition = {
  count: number;
};
export type PropertyGroups = Record<PROPERTY_STATUS, TProperty[] | null | undefined>;

export type SearchCriteria = {
  additional: string[];
  baths: number;
  beds: number;
  bedsRange: number[];
  coordinates: { latitude: number; longitude: number };
  exclusions: string[];
  id: string;
  ignoreSize: boolean;
  manualMlsIds: string[];
  mapCoordinates: { lat: number; lon: number }[];
  mapMode: 'marker' | 'polygon' | null;
  // propertyType: 'SINGLE_FAMILY'; create enum
  propertyType: string;
  propertyTypeSelections: { label: string; value: string }[]; //replace value type with enum once created
  radius: { label: number; value: number };
  reportCreatedAt: number;
  saleDateLimitRange: number[];
  size: number;
  sizeRange: number[];
  statusSelections: string[];
  totalSize: number;
  totalSizeRange: number[];
  totalSizeSet: boolean;
  // totalSizeUnit: 'sqft'; create enum
  totalSizeUnit: string;
  yearBuilt: number;
  yearBuiltFrom: number | null;
  yearBuiltRangeSet: boolean;
  yearBuiltTo: number | null;
};

export type TNote = {
  updated_at: number;
  created_at: number;
  property_id: string;
  note: string;
};

export type ReportCompAverages = {
  average_active: number;
  average_active_per_sqft: number;
  average_adult: number;
  average_adult_off_market: number;
  average_adult_off_market_per_sqft: number;
  average_adult_per_sqft: number;
  average_air_conditioning: number;
  average_air_conditioning_off_market: number;
  average_air_conditioning_off_market_per_sqft: number;
  average_air_conditioning_per_sqft: number;
  average_basement_finished: number;
  average_basement_finished_off_market: number;
  average_basement_finished_off_market_per_sqft: number;
  average_basement_finished_per_sqft: number;
  average_basement_none: number;
  average_basement_none_off_market: number;
  average_basement_none_off_market_per_sqft: number;
  average_basement_none_per_sqft: number;
  average_basement_partial: number;
  average_basement_partial_off_market: number;
  average_basement_partial_off_market_per_sqft: number;
  average_basement_partial_per_sqft: number;
  average_basement_unfinished: number;
  average_basement_unfinished_off_market: number;
  average_basement_unfinished_off_market_per_sqft: number;
  average_basement_unfinished_per_sqft: number;
  average_basement_yes: number;
  average_basement_yes_off_market: number;
  average_basement_yes_off_market_per_sqft: number;
  average_basement_yes_per_sqft: number;
  average_closed: number;
  average_closed_per_sqft: number;
  average_coming_soon: number;
  average_coming_soon_per_sqft: number;
  average_deck: number;
  average_deck_off_market: number;
  average_deck_off_market_per_sqft: number;
  average_deck_per_sqft: number;
  average_fixer_upper: number;
  average_fixer_upper_off_market: number;
  average_fixer_upper_off_market_per_sqft: number;
  average_fixer_upper_per_sqft: number;
  average_garage_0: number;
  average_garage_0_off_market: number;
  average_garage_0_off_market_per_sqft: number;
  average_garage_0_per_sqft: number;
  average_garage_1: number;
  average_garage_1_off_market: number;
  average_garage_1_off_market_per_sqft: number;
  average_garage_1_per_sqft: number;
  average_garage_2: number;
  average_garage_2_off_market: number;
  average_garage_2_off_market_per_sqft: number;
  average_garage_2_per_sqft: number;
  average_garage_3: number;
  average_garage_3_off_market: number;
  average_garage_3_off_market_per_sqft: number;
  average_garage_3_per_sqft: number;
  average_golf: number;
  average_golf_off_market: number;
  average_golf_off_market_per_sqft: number;
  average_golf_per_sqft: number;
  average_green: number;
  average_green_off_market: number;
  average_green_off_market_per_sqft: number;
  average_green_per_sqft: number;
  average_horse: number;
  average_horse_off_market: number;
  average_horse_off_market_per_sqft: number;
  average_horse_per_sqft: number;
  average_master_on_main: number;
  average_master_on_main_off_market: number;
  average_master_on_main_off_market_per_sqft: number;
  average_master_on_main_per_sqft: number;
  average_new_construction: number;
  average_new_construction_off_market: number;
  average_new_construction_off_market_per_sqft: number;
  average_new_construction_per_sqft: number;
  average_not_adult: number;
  average_not_adult_off_market: number;
  average_not_adult_off_market_per_sqft: number;
  average_not_adult_per_sqft: number;
  average_not_air_conditioning: number;
  average_not_air_conditioning_off_market: number;
  average_not_air_conditioning_off_market_per_sqft: number;
  average_not_air_conditioning_per_sqft: number;
  average_not_deck: number;
  average_not_deck_off_market: number;
  average_not_deck_off_market_per_sqft: number;
  average_not_deck_per_sqft: number;
  average_not_fixer_upper: number;
  average_not_fixer_upper_off_market: number;
  average_not_fixer_upper_off_market_per_sqft: number;
  average_not_fixer_upper_per_sqft: number;
  average_not_golf: number;
  average_not_golf_off_market: number;
  average_not_golf_off_market_per_sqft: number;
  average_not_golf_per_sqft: number;
  average_not_green: number;
  average_not_green_off_market: number;
  average_not_green_off_market_per_sqft: number;
  average_not_green_per_sqft: number;
  average_not_horse: number;
  average_not_horse_off_market: number;
  average_not_horse_off_market_per_sqft: number;
  average_not_horse_per_sqft: number;
  average_not_master_on_main: number;
  average_not_master_on_main_off_market: number;
  average_not_master_on_main_off_market_per_sqft: number;
  average_not_master_on_main_per_sqft: number;
  average_not_new_construction: number;
  average_not_new_construction_off_market: number;
  average_not_new_construction_off_market_per_sqft: number;
  average_not_new_construction_per_sqft: number;
  average_not_water_front: number;
  average_not_water_front_off_market: number;
  average_not_water_front_off_market_per_sqft: number;
  average_not_water_front_per_sqft: number;
  average_not_water_view: number;
  average_not_water_view_off_market: number;
  average_not_water_view_off_market_per_sqft: number;
  average_not_water_view_per_sqft: number;
  average_pending: number;
  average_pending_per_sqft: number;
  average_water_front: number;
  average_water_front_off_market: number;
  average_water_front_off_market_per_sqft: number;
  average_water_front_per_sqft: number;
  average_water_view: number;
  average_water_view_off_market: number;
  average_water_view_off_market_per_sqft: number;
  average_water_view_per_sqft: number;
  average_with_pool: number;
  average_with_pool_off_market: number;
  average_with_pool_off_market_per_sqft: number;
  average_with_pool_per_sqft: number;
  average_with_view: number;
  average_with_view_off_market: number;
  average_with_view_off_market_per_sqft: number;
  average_with_view_per_sqft: number;
  average_without_pool: number;
  average_without_pool_off_market: number;
  average_without_pool_off_market_per_sqft: number;
  average_without_pool_per_sqft: number;
  average_without_view: number;
  average_without_view_off_market: number;
  average_without_view_off_market_per_sqft: number;
  average_without_view_per_sqft: number;
  bath_1: number;
  bath_1_off_market: number;
  bath_1_off_market_per_sqft: number;
  bath_1_per_sqft: number;
  bath_2: number;
  bath_2_off_market: number;
  bath_2_off_market_per_sqft: number;
  bath_2_per_sqft: number;
  bath_3: number;
  bath_3_off_market: number;
  bath_3_off_market_per_sqft: number;
  bath_3_per_sqft: number;
  bath_minus_1: number;
  bath_minus_1_off_market: number;
  bath_minus_1_off_market_per_sqft: number;
  bath_minus_1_per_sqft: number;
  bath_minus_2: number;
  bath_minus_2_off_market: number;
  bath_minus_2_off_market_per_sqft: number;
  bath_minus_2_per_sqft: number;
  bath_minus_3: number;
  bath_minus_3_off_market: number;
  bath_minus_3_off_market_per_sqft: number;
  bath_minus_3_per_sqft: number;
  bath_same: number;
  bath_same_off_market: number;
  bath_same_off_market_per_sqft: number;
  bath_same_per_sqft: number;
  closest_active: number;
  closest_active_per_sqft: number;
  closest_closed: number;
  closest_closed_per_sqft: number;
  closest_coming_soon: number;
  closest_coming_soon_per_sqft: number;
  closest_pending: number;
  closest_pending_per_sqft: number;
  highest_active: number;
  highest_active_per_sqft: number;
  highest_closed: number;
  highest_closed_per_sqft: number;
  highest_coming_soon: number;
  highest_coming_soon_per_sqft: number;
  highest_pending: number;
  highest_pending_per_sqft: number;
  hoa_0: number;
  hoa_0_off_market: number;
  hoa_0_off_market_per_sqft: number;
  hoa_0_per_sqft: number;
  hoa_1: number;
  hoa_1_off_market: number;
  hoa_1_off_market_per_sqft: number;
  hoa_1_per_sqft: number;
  level_1: number;
  level_1_off_market: number;
  level_1_off_market_per_sqft: number;
  level_1_per_sqft: number;
  level_2: number;
  level_2_off_market: number;
  level_2_off_market_per_sqft: number;
  level_2_per_sqft: number;
  level_3: number;
  level_3_off_market: number;
  level_3_off_market_per_sqft: number;
  level_3_per_sqft: number;
  lowest_active: number;
  lowest_active_per_sqft: number;
  lowest_closed: number;
  lowest_closed_per_sqft: number;
  lowest_coming_soon: number;
  lowest_coming_soon_per_sqft: number;
  lowest_pending: number;
  lowest_pending_per_sqft: number;
  median_active: number;
  median_active_per_sqft: number;
  median_closed: number;
  median_closed_per_sqft: number;
  median_coming_soon: number;
  median_coming_soon_per_sqft: number;
  median_pending: number;
  median_pending_per_sqft: number;
  recent_active: number;
  recent_active_per_sqft: number;
  recent_closed: number;
  recent_closed_per_sqft: number;
  recent_coming_soon: number;
  recent_coming_soon_per_sqft: number;
  recent_pending: number;
  recent_pending_per_sqft: number;
};

export type CompAverages = ReportCompAverages & { adjusted: Partial<ReportCompAverages> };

export type ReportEstimates = {
  attom: number;
  attom_per_sqft: number;
};

export type ReportFlagsResults = {
  bed_one_results: TProperty[];
  bed_two_results: TProperty[];
  size_one_results: TProperty[];
  size_two_results: TProperty[];
  bed_three_results: TProperty[];
  size_four_results: TProperty[];
  size_three_results: TProperty[];
  bed_one_negative_results: TProperty[];
  bed_two_negative_results: TProperty[];
  size_one_negative_results: TProperty[];
  size_two_negative_results: TProperty[];
  bed_three_negative_results: TProperty[];
  size_three_negative_results: TProperty[];
};

export type ReportFlagValues = {
  bed_one: number;
  bed_two: number;
  size_one: number;
  size_two: number;
  bed_three: number;
  size_four: number;
  size_three: number;
  bed_one_negative: number;
  bed_one_per_sqft: number;
  bed_two_negative: number;
  bed_two_per_sqft: number;
  size_one_negative: number;
  size_one_per_sqft: number;
  size_two_negative: number;
  size_two_per_sqft: number;
  bed_three_negative: number;
  bed_three_per_sqft: number;
  size_four_per_sqft: number;
  size_three_negative: number;
  size_three_per_sqft: number;
  bed_one_negative_per_sqft: number;
  bed_two_negative_per_sqft: number;
  size_one_negative_per_sqft: number;
  size_two_negative_per_sqft: number;
  bed_three_negative_per_sqft: number;
  size_three_negative_per_sqft: number;
};

export type ReportFlags = ReportFlagsResults & ReportFlagValues;

type TCustomizedData = {
  getProperties?: (
    properties: PropertyGroups,
    sqftModeOn?: boolean,
    subject?: TProperty,
  ) => PropertyGroups;
  getHighlight?: (properties: PropertyGroups) => TProperty | undefined;
  getFlagsProperties?: (flagResults: ReportFlagsResults) => PropertyGroups;
  source?: string;
  getCount?: (properties: PropertyGroups, subject?: TProperty) => number;
  getFlaggedCount?: (flagResults: ReportFlagsResults) => number;
};

export type TReportSelectedCategory = {
  customizedData: TCustomizedData | null;
  icon: string;
  id: keyof ReportCompAverages | keyof ReportEstimates | keyof ReportFlagValues;
  name: string;
  value?: number;
  valuePerSqft?: number;
};

export type TCompConfigItem = {
  label: string;
  labelHtml: string;
  id: keyof ReportCompAverages | keyof ReportEstimates | keyof ReportFlagValues;
  idPerSqft: keyof ReportCompAverages | keyof ReportEstimates | keyof ReportFlagValues;
  customizedData: TCustomizedData | null;
  icon: string;
};

export type THighlightedProperty = {
  id: string;
  price: number;
  adjustedPrice?: number | null;
  pricePerSqft: number;
  adjustedPricePerSqft?: number | null;
  name?: string;
  status: string;
  beds: number;
  size: number;
  distance: number;
  label?: string;
  priceFrom?: string;
  priceTo?: string;
};

export type TLogoListItem = {
  position: number;
  value: string;
};
export type TCustomMarker = {
  name: string;
  price: number | null;
  priceFrom: number | null;
  pricePerSqft: number | null;
  priceTo: number | null;
};

export type SearchAPI = {
  id: string;
  ignore_size: boolean;
  bed_from: number;
  bed_to: number;
  size_from: number;
  size_to: number;
  total_size_set: boolean;
  total_size_from: number;
  total_size_to: number;
  total_size_unit: string;
  year_built_range_set: boolean;
  year_built_from: number | null;
  year_built_to: number | null;
  sale_date_limit_from: number;
  sale_date_limit_to: number;
  additional_ids: string[];
  lat: number;
  lon: number;
  radius: number;
  excluded_ids: string[];
  property_type: string[];
  subject: {
    beds: number;
    property_type: string[];
    status: null;
    size: number;
    total_size: number;
    coordinates: { latitude: number; longitude: number };
    year_built: number;
    latitude: number;
    longitude: number;
  };
};

export type TablePropertyStatus = Exclude<PROPERTY_STATUS, PROPERTY_STATUS.COMING_SOON>;

export type TablePDFAverageData = {
  label?: string;
  bd: number;
  bth: number;
  built: number | string;
  cdom: number;
  price: string;
  pricePerSq: string;
  salePrice: string;
  sqft: string;
};

export type TPropertyRowPdf = TablePDFAverageData & {
  id: string;
  address: string;
  highlighted: boolean;
  status: string;
  listDate: string;
  adjustedPrice: string | null;
};

export type TablePDFPropertyGroupData = {
  average?: TablePDFAverageData | {};
  rows: TProperty[];
};

export type TablePDFPageData = Record<Partial<TablePropertyStatus>, TablePDFPropertyGroupData>;

export type TablePDFPropertiesPage = Record<number, TablePDFPageData | {}>;

export type StatusShort = 'c' | 'p' | 'a' | 'coming_soon';

export type PropertyMarker = google.maps.Marker & {
  infoWindowContent?: string;
};

export type TWhiteLabelSlidesProp = {
  packageLevel: string;
  primaryColor: string;
  secondaryColor: string;
};

export interface WhiteLabelSettings {
  primaryColor: string;
  secondaryColor: string;
  loginLogo: string;
  largeLogo: string;
  smallLogo: string;
  favicon: string;
  loginBackground: string;
  packageLevel: string;
}
export interface UserSettings {
  hasCorePresent: boolean;
  hasBoldTrail: boolean;
  whiteLabelSettings: WhiteLabelSettings | null;
}

export enum RuntimeEnvironment {
  DEV = 'development',
  STAGING = 'staging',
  PROD = 'production',
}

type label =
  | 'presentationCount'
  | 'avgDuration'
  | 'avgOpenRate'
  | 'avgSellerResponse'
  | 'avgBuyerResponse';

export type TAnalyticsSummary = {
  [K in label]: string | number | null;
} & {
  avgBuyerFeedbackOption: keyof typeof MORE_TIME_DESCRIPTIONS;
  avgSellerFeedbackOption: keyof typeof MORE_TIME_DESCRIPTIONS;
};

export type TAnalyticsSummaryData = {
  label: string;
  value?: string | number | null;
  isSmallFont?: boolean;
};
