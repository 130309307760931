import { ApiMapper } from '../ApiMapper';
import { ReportDetailed } from '../../entities/ReportDetailed';
import { updateEntityProperties } from '../../entities/services/EntityPropertiesUpdater';
import { PropertyApiMapper } from '../Property/PropertyApiMapper';
import { getPresentationType } from '../../../../../services/getPresentationType';

export class ReportDetailedApiMapper extends ApiMapper {
  static apiToEntity(apiReportDetailed) {
    const flagsWithProperties = {};
    const apiFlags = apiReportDetailed.flags;
    for (const key in apiFlags) {
      const value = apiFlags[key];
      if (!Array.isArray(value)) continue;
      flagsWithProperties[key] = value.map(PropertyApiMapper.apiToEntity);
    }

    return updateEntityProperties(new ReportDetailed(), {
      subject: PropertyApiMapper.apiToEntity(apiReportDetailed.subject),
      properties: {
        active: apiReportDetailed.properties.active.map(PropertyApiMapper.apiToEntity),
        comingSoon: apiReportDetailed.properties.coming_soon.map(PropertyApiMapper.apiToEntity),
        pending: apiReportDetailed.properties.pending.map(PropertyApiMapper.apiToEntity),
        sold: apiReportDetailed.properties.sold.map(PropertyApiMapper.apiToEntity),
      },
      info: apiReportDetailed.info,
      graphs: apiReportDetailed.graphs,
      flags: { ...apiFlags, ...flagsWithProperties },
      estimates: apiReportDetailed.estimates,
      compAverages: apiReportDetailed.comp_averages,
      presentationId: apiReportDetailed.presentation?.id,
      presentationHash: apiReportDetailed.presentation?.hash,
    });
  }

  static entityToApi(reportDetailed) {
    return {
      ...reportDetailed,
      presentation_type: getPresentationType(reportDetailed.presentation_type),
    };
  }
}
