import React, { Component } from 'react';
import { withTheme } from 'styled-components';

import { Map } from '../../../view/components/Map/Map';
import { CurrentUserService } from '../../../../../../services/CurrentUserService';
import { MapHelpers } from '../../../view/pages/Authenticated/PropertySearch/PropertyArea/AdrSelection/DrawableMarkerPolylineMap/helpers';
import { mapStyles } from '../../../view/pages/Authenticated/PropertySearch/PropertyArea/AdrSelection/DrawableMarkerPolylineMap/MapStyles';
import { BOLD_TRAIL_THEME_NAME } from '../../../../../../config/themes/bold-trail';

class ViewOnlyMarkerPolylineMapImp extends Component {
  mapHelper;

  getRadiusFactor() {
    return CurrentUserService.isCanadaUser() ? 0.621371 : 1;
  }

  render() {
    const {
      defaultCenter,
      currentRadius,
      style,
      initCoordinates = [],
      mapMode,
      color,
      onMapLoad = f => f,
      isPdf = false,
    } = this.props;

    const isBoldTrailTheme = this.props.theme.name === BOLD_TRAIL_THEME_NAME;

    return (
      <Map
        isPdf={isPdf}
        options={{
          center: { ...defaultCenter },
          zoom: 14.25,
          styles: mapStyles,
          disableDefaultUI: true,
        }}
        onMapLoad={(map, google, oms) => {
          this.mapHelper = new MapHelpers(this.props.theme, map, google, oms);
          this.mapHelper.createSubjectMarker(defaultCenter);

          if (mapMode === 'marker' || mapMode == null) {
            this.mapHelper.drawCircle(defaultCenter, currentRadius * this.getRadiusFactor(), color);
            this.mapHelper.extendBoundsToCircle();
          }

          if (mapMode === 'polygon') {
            this.mapHelper.drawPolygon(initCoordinates, color);
            this.mapHelper.extendBoundsToPolygon();
            this.mapHelper.setDrawingMode('polygon');
          }

          if (this.props.properties) {
            this.mapHelper.setMarkersFromComps(
              this.props.properties,
              this.props.subjectSize,
              true,
              isBoldTrailTheme,
            );
            this.mapHelper.extendBoundsToFitComparables(defaultCenter);
            this.mapHelper.setDrawingMode(null);
          }

          onMapLoad();
        }}
        style={style}
      />
    );
  }
}

export const ViewOnlyMarkerPolylineMap = withTheme(ViewOnlyMarkerPolylineMapImp);
