import { useAuthMe } from '../useAuthMe';
import { usePresentation } from '../usePresentation';
import { useIsLoggedIn } from '../../features/auth/useIsLoggedIn';
import { useUser } from '../../components/Slide/providers/UserProvider';
import { usePresentationHashFromUrl } from '../../pages/Presentation/hooks/usePresentationHashFromUrl';

export function useFeatureFlagIds() {
  const isAuth = useIsLoggedIn();
  const { data: apiUser } = useAuthMe(isAuth);

  const user = useUser();

  const hash = usePresentationHashFromUrl();
  const { data: presentation } = usePresentation(hash, { omitGlobalStoreSet: true });

  const presentationUser = presentation?.user as any;
  const accountId =
    presentationUser?.kvcore_account_id || apiUser?.kvcore_account_id || user?.accountId || 0;
  const kvCoreUserId =
    presentationUser?.kvcore_user_id || apiUser?.kvcore_user_id || user?.kvcoreUserId || 0;

  return { accountId, kvCoreUserId };
}
