import styled from 'styled-components';

import { CATEGORIES } from '../../../../../../constants/categories';
import { useCategory } from '../../../../../../providers/providers/CategoryProvider';
import { useUiConfigColorsPrimary } from '../../../../../../providers/providers/UiConfigColorProvider';
import { useSlides } from '../../../../../../providers/providers/SlidesProvider';
import { useAllSearchCriteria } from '../../../../../../pages/PresentationCreate/dash/ReportFacade/data/useSearchCriterias';
import { useSubjectInfo } from '../../../../../../pages/PresentationCreate/dash/ReportFacade/data/useSubjectInfo';
import { PdfFrame } from '../../../../components/SlideFrame/frames/pdf/PdfFrame';
import Map from '../Map/Map';

export const BuyerDemandPdf = () => {
  const { category } = useCategory();
  const { getSlide } = useSlides();

  const primaryColor = useUiConfigColorsPrimary();

  const slide = getSlide('buyerDemand');

  const searchCriteria = useAllSearchCriteria();
  const subject = useSubjectInfo();

  const zipData = slide?.buyerDemandData?.zipData;
  const properties = slide?.buyerDemandData?.properties;

  return (
    <PdfFrame category={category ?? CATEGORIES.TRADITIONAL}>
      <Container>
        <GridWrapper>
          <Card color={primaryColor}>
            <Title>BUYER DEMAND</Title>
            <Description>
              The law of supply and demand dictates the equilibrium price of a property, and I can
              show you if buyers are searching in your area now.
            </Description>
          </Card>
          <Card>
            <Title>COMPARABLE SUPPLY</Title>
            <Description>
              To help put the demand in perspective, I layered in the supply of comparable
              properties within a close proximity & properties that just miss the mark for being
              considered a comparable.
            </Description>
          </Card>
        </GridWrapper>
        <MapWrapper>
          <Map
            subject={subject}
            zoomLevel={12}
            zipData={zipData}
            properties={properties}
            searchCriteria={searchCriteria}
          />
        </MapWrapper>
      </Container>
    </PdfFrame>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const GridWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
`;

const Card = styled.div<{ color?: string }>`
  display: flex;
  flex-direction: column;
  gap: 7px;
  padding: 20px;
  border-radius: 4px;
  border-radius: 3.5px;
  border: ${({ color }) => (color ? 'none' : '1px solid #B1B1B1')};
  background: ${({ color }) =>
    `linear-gradient(0deg, rgba(255, 255, 255, 0.95) 0%, rgba(255, 255, 255, 0.95) 100%), ${
      color || '#FFF'
    }`};
`;

const Title = styled.p`
  color: #444;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 900;
  line-height: 133.2%;
  letter-spacing: 0.5px;
  text-transform: uppercase;
`;

const Description = styled.p`
  color: #444;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 162%;
`;

const MapWrapper = styled.div`
  border-radius: 5px;
  display: flex;
  height: 600px;
  align-items: center;
  justify-content: center;
  background-color: #dddddd;
  overflow: hidden;
`;
