import React, { useState } from 'react';
import { useUser } from '../../../../../../components/Slide/providers/UserProvider';
import { useCategory } from '../../../../../../providers/providers/CategoryProvider';
import { useUiConfigs } from '../../../../../../providers/providers/UiConfigProvider';
import {
  TCategory,
  TSlide,
  TSlideId,
  TSlideInfoPlacement,
  TSlideTheme,
} from '../../../../../../types';
import {
  ButtonsWrapper,
  PresentButtonsContainer,
  PresentButtonsTitle,
} from './SlidePresent.styles';
import { PresentationApi } from '../../../../../Presentation/api/PresentationApi';
import { Button } from '../../../../../../components/Common/V2/Button/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDomainWhitelabelSettings } from '../../../../../../features/whitelabel/useDomainWhitelabelSettings';

interface SlidePresentProps {
  slide: TSlide;
  theme: TSlideTheme;
  infoPlacement: TSlideInfoPlacement;
}

type TSingleSlideDownloadPayload = {
  category?: TCategory;
  theme: TSlideTheme;
  slideId: TSlideId;
  userHash: string;
  pdf?: string;
  packageLevel?: string | null;
  primaryColor?: string;
  secondaryColor?: string;
};

export const SlidePresent: React.FC<SlidePresentProps> = ({ slide, theme, infoPlacement }) => {
  const ui = useUiConfigs();
  const { category } = useCategory();

  const { userId, hash } = useUser();

  const [isBuildingPdf, setIsBuildingPdf] = useState(false);
  const { data } = useDomainWhitelabelSettings();

  const onDownloadSingleSlide = async () => {
    let obj: TSingleSlideDownloadPayload = {
      category,
      theme,
      slideId: slide.id as TSlideId,
      userHash: hash,
      packageLevel: data?.whiteLabelSettings?.packageLevel,
      primaryColor: data?.whiteLabelSettings?.primaryColor,
      secondaryColor: data?.whiteLabelSettings?.secondaryColor,
    };

    if (slide?.data?.pdf || slide?.data?.webPdf) {
      obj = {
        ...obj,
        pdf: slide?.data?.pdf || slide?.data?.webPdf,
      };
    }

    try {
      setIsBuildingPdf(true);
      await PresentationApi.singlePdfGenerate(obj, slide);
    } catch (e: any) {
      console.error('Building PDF ERROR: ', e);
    } finally {
      setIsBuildingPdf(false);
    }
  };

  const onOpenSlidePreview = () => {
    window.open(
      `/present/slide/${slide.id}?category=${category}&theme=${theme}&infoPlacement=${infoPlacement.value}&userId=${userId}&hash=${hash}&content='someContent'`,
      '_blank',
    );
  };

  return (
    <PresentButtonsContainer>
      <PresentButtonsTitle>Present & Share</PresentButtonsTitle>
      <ButtonsWrapper>
        <Button
          variant='secondary'
          prefixIcon={<FontAwesomeIcon icon={['fas', 'circle-play']} />}
          onClick={onOpenSlidePreview}
        >
          Open
        </Button>

        <Button
          variant='secondary'
          isLoading={isBuildingPdf}
          prefixIcon={<FontAwesomeIcon icon={['fas', 'print']} />}
          disabled={!!slide?.data?.videoUrl}
          onClick={onDownloadSingleSlide}
        >
          Print
        </Button>
      </ButtonsWrapper>
    </PresentButtonsContainer>
  );
};
