import { useSelector } from 'react-redux';
import React, { useEffect, useRef } from 'react';

import styles from './SubjectPropertyInfo.module.scss';
import { useUser } from '../../../../../../../../../components/Slide/providers/UserProvider';
import { NumberFormatter } from '../../../../../../services/numberFormatter';

const DivWidthTracker = ({ children, watchElementWithId, className = '' }) => {
  const div = useRef(null);

  useEffect(() => {
    const element = document.querySelector(`#${watchElementWithId}`);

    if (div?.current && element) {
      div.current.style.width = `${element.clientWidth}px`;
    }
  });

  return (
    <div className={className} ref={div}>
      {children}
    </div>
  );
};

export const SubjectPropertyInfo = () => {
  const { isCanadaUser } = useUser();
  const sc = useSelector(({ search }) => search.searchCriteria);

  const propertyType = sc.propertyType?.[0]?.label ?? '--';
  const beds = isNaN(parseInt(sc.beds)) ? '--' : `${sc.beds} Beds`;
  const size = sc.size ? `${sc.size} Sqft` : '--';
  const yearBuild = sc.yearBuilt ? sc.yearBuilt : '--';
  const total = sc.totalSize ? sc.totalSize : '--';

  return (
    <div className={styles.subjectInfo}>
      <DivWidthTracker watchElementWithId='search-pill-criteria' className={styles.criteria}>
        Subject <br /> Property
      </DivWidthTracker>
      <DivWidthTracker watchElementWithId='search-pill-propertyType'>
        {propertyType}
      </DivWidthTracker>
      <DivWidthTracker watchElementWithId='search-pill-beds'>{beds}</DivWidthTracker>
      {!isCanadaUser && (
        <DivWidthTracker watchElementWithId='search-pill-size'>
          {NumberFormatter.format(size)}
        </DivWidthTracker>
      )}
      <DivWidthTracker watchElementWithId='search-pill-year'>{yearBuild}</DivWidthTracker>
      <DivWidthTracker watchElementWithId='search-pill-dates'>--</DivWidthTracker>
      {!isCanadaUser && sc.totalSizeSet && (
        <DivWidthTracker watchElementWithId='search-pill-total'>{total}</DivWidthTracker>
      )}
    </div>
  );
};
