import { TProperty } from '../../../../types';

export const getShelflifeAverage = (properties: TProperty[]) => {
  const activeProperties = properties.filter(prop => prop.status === 'active');

  if (!activeProperties.length) return 0;

  const daysOnMarket = activeProperties.map(p => p.daysOnMarket ?? 0) || [];

  const avgDaysOnMarket = daysOnMarket.reduce((a, b) => a + b, 0) / daysOnMarket.length;

  return Math.round(avgDaysOnMarket);
};
