import { Fragment, useState } from 'react';
import { useAtomValue } from 'jotai';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { TCategory } from '../../../../types';
import { BasicModal } from '../../../../components/Common/BasicModal';
import { pricingRateOfChangeAtom } from '../../state/pricingRateOfChangeAtom';
import PricingRangeOfChangeSlide from '../../../../components/Slide/slides/marketAnalysis/marketOverview/components/pricingRateOfChange/web/PricingRateOfChangeSlide';
import { MarketAssessmentCard } from './MarketAssessmentCard';
import bgImg from './assets/pricing-rate-of-change-dark.svg?url';
import bgImgLight from './assets/pricing-rate-of-change.svg?url';

interface PricingRateOfChangeGaugeProps {
  trackClick?: () => void;
  category?: TCategory;
  oneSheeterSlide?: boolean;
}

export const PricingRateOfChangeGauge = ({
  trackClick,
  category,
  oneSheeterSlide,
}: PricingRateOfChangeGaugeProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const data = useAtomValue(pricingRateOfChangeAtom);

  const openPopup = () => {
    setIsOpen(true);
    trackClick?.();
  };

  const closePopup = () => {
    setIsOpen(false);
  };

  const id = oneSheeterSlide ? 'oneSheeter' : 'marketOverview';
  const content = data?.text || '';

  return (
    <Fragment>
      <MarketAssessmentCard
        headline='Pricing Rate of Change'
        content={content}
        description='Average rate of change between listed and recorded price of sold comps'
        bgImg={bgImg}
        bgImgLight={bgImgLight}
        clicked={openPopup}
        hasCircle
        disabled={!data?.props?.length}
        oneSheeter={oneSheeterSlide}
        sup={<FontAwesomeIcon icon={['fas', 'percent']} fontSize={20} />}
      />

      <BasicModal id={id} isOpen={isOpen} onClose={closePopup}>
        <PricingRangeOfChangeSlide category={category || 'traditional'} />
      </BasicModal>
    </Fragment>
  );
};
