import { propertyTypeOptions } from '../../view/pages/Authenticated/PropertySearch/PropertyDetails/PropertySearchCriteria/Criterias/PropertyType/propertyTypeOptions';

export class AddressToReportBuildRequestAdapter {
  public static toApi(propertyCriteria: any) {
    if (!propertyCriteria?.id) return null;

    const propertyTypeOption = propertyTypeOptions.find(
      po => po.value === propertyCriteria.propertyType,
    );

    return {
      id: propertyCriteria.id,
      subject: {
        beds: propertyCriteria.beds,
        property_type: [[propertyTypeOption]],
        status: null,
        size: propertyCriteria.size,
        total_size: propertyCriteria.totalSize,
        coordinates: propertyCriteria.coordinates,
        year_built: propertyCriteria.yearBuilt,
        latitude: propertyCriteria.coordinates.latitude,
        longitude: propertyCriteria.coordinates.longitude,
      },
      property_type: [propertyCriteria.propertyType],
      bed_from: propertyCriteria.beds,
      bed_to: propertyCriteria.beds,
      size_from: Math.round(propertyCriteria.size * 0.8),
      size_to: Math.round(propertyCriteria.size * 1.2),
      total_size_set: false,
      total_size_from: 0,
      total_size_to: 0,
      total_size_unit: 'sqft',
      year_built_range_set: false,
      year_built_from: null,
      year_built_to: null,
      sale_date_limit_from: 0,
      sale_date_limit_to: 6,
      additional_ids: [],
      lat: propertyCriteria.coordinates.latitude,
      lon: propertyCriteria.coordinates.longitude,
      radius: 1,
      excluded_ids: [],
    };
  }

  public static toDefaultSearchCriteria(searchCriteriaApi: any, searchCriteriaState: any) {
    if (!searchCriteriaApi?.id) return null;

    const propertyTypeOption = propertyTypeOptions.find(
      po => po.value === searchCriteriaApi.property_type[0],
    );

    return {
      id: searchCriteriaApi.id,
      beds: searchCriteriaApi.beds ?? searchCriteriaState.beds,
      size: searchCriteriaApi.size ?? searchCriteriaState.size,
      baths: searchCriteriaApi.baths ?? searchCriteriaState.baths,
      radius: {
        label: String(searchCriteriaApi.radius),
        value: searchCriteriaApi.radius,
      },
      status: null,
      mapMode: 'marker',
      bedsInit: searchCriteriaApi.beds ?? searchCriteriaState.beds,
      sizeInit: searchCriteriaApi.size ?? searchCriteriaState.size,
      bedsRange: [0, 0],
      sizeRange: [searchCriteriaApi.size_from, searchCriteriaApi.size_to],
      totalSize: searchCriteriaApi.subject.total_size,
      yearBuilt: searchCriteriaApi.subject.year_built,
      additional: [],
      exclusions: [],
      initRadius: {
        label: String(searchCriteriaApi.radius),
        value: searchCriteriaApi.radius,
      },
      reportType: 'auto',
      coordinates: {
        latitude: searchCriteriaApi.subject.latitude ?? searchCriteriaState.coordinates.latitude,
        longitude: searchCriteriaApi.subject.longitude ?? searchCriteriaState.coordinates.longitude,
      },
      initMapMode: 'marker',
      yearBuiltTo: new Date().getFullYear(),
      manualMlsIds: [],
      propertyType: [propertyTypeOption],
      totalSizeSet: searchCriteriaApi.total_size_set,
      totalSizeUnit: searchCriteriaApi.total_size_unit,
      yearBuiltFrom: searchCriteriaApi.subject.year_built,
      mapCoordinates: [
        {
          lat: searchCriteriaApi.subject.latitude ?? searchCriteriaState.coordinates.latitude,
          lng: searchCriteriaApi.subject.longitude ?? searchCriteriaState.coordinates.longitude,
        },
      ],
      totalSizeRange: [searchCriteriaApi.total_size_from, searchCriteriaApi.total_size_to],
      propertyTypeInit: [propertyTypeOption],
      yearBuiltRangeSet: searchCriteriaApi.year_built_range_set,
      initMapCoordinates: [
        {
          lat: searchCriteriaApi.subject.latitude ?? searchCriteriaState.coordinates.latitude,
          lng: searchCriteriaApi.subject.longitude ?? searchCriteriaState.coordinates.longitude,
        },
      ],
      saleDateLimitRange: [
        searchCriteriaApi.sale_date_limit_from,
        searchCriteriaApi.sale_date_limit_to,
      ],
      propertyTypeSelections: [propertyTypeOption],
    };
  }
}
