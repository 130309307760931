import { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { LivingAreaSizeEdit } from './LivingAreaSizeEdit';
import { SearchCombiner } from '../../../../SearchCombiner';
import { SearchValidator } from '../../../../SearchValidator';
import { PercentageRange } from './PercentageRange/PercentageRange';
import { CriteriaOptionsSubtitle } from '../components/CriteriaOptionsSubtitle/CriteriaOptionsSubtitle';

import classes from './LivingAreaSize.module.scss';
import { CRITERIAS } from '../../../Criterias';
import { useCriteriaIsOpen } from '../../../CriteriaIsOpenProvider';
import { CriteriaAccordion } from '../components/CriteriaAccordion';
import { Checkbox } from '../../../../../../../../../../../features/report/components/Checkbox';

const info =
  'Data is pulled directly from PropertyModal records for the subject. The home icon on the scale symbolizes the subject property and notes the living area square footage of the subject. Note: the living area is the size of the home itself, not the lot. The orange dots can be adjusted higher or lower than the subject property to indicate the square footage range to apply to the comp search. It is recommended to start with a narrow range and expand as necessary to bring in more results.';

const getLivingSizeOptions = size => {
  const step = Math.round(size / 20);
  return new Array(41)
    .join('0')
    .split('0')
    .map((v, i) => ({
      value: i * step,
      label: '',
    }));
};

const LivingAreaSizeComponent = ({ searchCriteria, className, onChange }) => {
  const [size, setSize] = useState(null);
  const [sizeDefault, setSizeDefault] = useState(null);
  const [sizeRange, setSizeRange] = useState([0, 0]);
  const [isOpen] = useCriteriaIsOpen(CRITERIAS.SIZE);

  const [ignoreSize, setIgnoreSize] = useState(false);

  const validate = () => {
    const min = Math.min(...sizeRange);
    const max = Math.max(...sizeRange);

    return !Number.isNaN(Number.parseInt(min)) && !Number.isNaN(Number.parseInt(max));
  };

  const onCombine = () => ({
    size,
    sizeInit: sizeDefault,
    sizeRange,
    ignoreSize,
  });

  const onEdit = val => {
    setSize(val);
    setSizeRange([Math.round(val * 0.8), Math.round(val * 1.2)]);
  };

  useEffect(() => {
    const initSize = searchCriteria.size || size;
    setSize(initSize);
    setSizeDefault(searchCriteria.sizeInit || initSize);
    setIgnoreSize(!!searchCriteria.ignoreSize);

    const initSizeRange = !searchCriteria.sizeRange
      ? [Math.round(initSize * 0.8), Math.round(initSize * 1.2)]
      : searchCriteria.sizeRange;
    setSizeRange(initSizeRange);
  }, []);

  useEffect(() => {
    SearchValidator.subscribe('size', validate);
    SearchCombiner.add('size', onCombine);
    onChange(SearchCombiner.combine());

    return () => {
      SearchValidator.unsubscribe('size');
      SearchCombiner.remove('size');
    };
  }, [size, sizeRange]);

  useEffect(() => {
    SearchCombiner.add('ignoreSize', onCombine);
    onChange(SearchCombiner.combine());

    return () => {
      SearchCombiner.remove('ignoreSize');
    };
  }, [ignoreSize]);

  if (!size) {
    return null;
  }

  return (
    <CriteriaAccordion title='SQUARE FOOTAGE' info={info} isOpen={isOpen}>
      <LivingAreaSizeEdit
        subject={size}
        onSubjectChange={onEdit}
        defaultSubjectValue={sizeDefault}
      />

      <CriteriaOptionsSubtitle />

      <PercentageRange
        options={getLivingSizeOptions(size)}
        value={sizeRange}
        changed={setSizeRange}
        size={size}
        className={classes.range}
      />
      <Checkbox
        label={
          "Include listings that don't list square footage but align with all other selected criteria"
        }
        onChange={setIgnoreSize}
        value={ignoreSize}
        labelStyles={{ fontSize: '12px', fontWeight: 500, textAlign: 'left' }}
        containerStyles={{ alignItems: 'flex-start' }}
      />
    </CriteriaAccordion>
  );
};

const stateToProps = ({ search }) => ({
  searchCriteria: search.searchCriteria,
});

export const LivingAreaSize = connect(stateToProps)(LivingAreaSizeComponent);
