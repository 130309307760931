export class ReportDetailed {
  subject = null;
  properties = null;
  info = null;
  graphs = null;
  flags = null;
  estimates = null;
  compAverages = null;
  presentationId = null;
  presentationHash = null;
}
