import React from 'react';
import PropTypes from 'prop-types';
import { debounce } from 'debounce';
import Autosuggest from 'react-autosuggest';

import styles from './Autocomplete.module.scss';
import { Input } from '../../../../../../../../../../components/Common/V2/Input';

const getSuggestions = (options, value) => {
  const inputValue = value.trim().toLowerCase();
  const inputLength = inputValue.length;

  return inputLength === 0 ? [] : options;
};

const getSuggestionValue = suggestion => suggestion.label;
const renderSuggestion = suggestion => <div>{suggestion.label}</div>;

class Autocomplete extends React.Component {
  static propTypes = {
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onChange: PropTypes.func,
    label: PropTypes.string,
    name: PropTypes.string,
    placeholder: PropTypes.string,
    className: PropTypes.string,
    error: PropTypes.string,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
      }),
    ),
    asyncOptionsProvider: PropTypes.func,
    debaunceTime: PropTypes.number,
    disabled: PropTypes.bool,
    dataAttributes: PropTypes.shape(PropTypes.any),
    required: PropTypes.bool,
    corePresent: PropTypes.bool,
  };

  static defaultProps = {
    value: '',
    onChange: f => f,
    label: '',
    name: '',
    placeholder: '',
    className: '',
    error: '',
    options: [],
    asyncOptionsProvider: null,
    debaunceTime: 500,
    disabled: false,
    dataAttributes: null,
    required: false,
  };

  constructor(props) {
    super(props);

    this.state = {
      value: '',
      suggestions: [],
      options: this.props.options,
      fetching: false,
    };

    this.fetchAsyncOptions = debounce(this.fetchAsyncOptions, this.props.debaunceTime);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    return {
      ...prevState,
      value: nextProps.value || '',
    };
  }

  onChange = (event, { newValue }) => {
    this.setState({
      value: newValue,
    });

    this.props.onChange(newValue);
  };

  fetchAsyncOptions = value => {
    const { asyncOptionsProvider } = this.props;

    asyncOptionsProvider(value)
      .then(options =>
        this.setState({
          options,
          suggestions: getSuggestions(options, value),
        }),
      )
      .finally(() => this.setState({ fetching: false }));
  };

  onSuggestionsFetchRequested = ({ value }) => {
    const { asyncOptionsProvider } = this.props;

    if (typeof asyncOptionsProvider === 'function') {
      this.setState({ fetching: true });
      this.fetchAsyncOptions(value);
    }

    return this.setState({
      suggestions: getSuggestions(this.state.options, value),
    });
  };

  onSuggestionsClearRequested = () => {};

  render() {
    const {
      className,
      label,
      error,
      disabled,
      placeholder = '',
      required,
      corePresent,
    } = this.props;
    const { value, suggestions, fetching } = this.state;

    const { inputLabel, input, loading, errorInput, ...theme } = styles;

    let inputClasses = input;
    if (fetching) {
      inputClasses = `${inputClasses} ${loading}`;
    }

    if (error) {
      inputClasses = `${inputClasses} ${errorInput}`;
    }

    if (corePresent) {
      inputClasses += ` ${styles.cpInput}`;
    }

    const inputProps = {
      placeholder,
      value: `${value}`,
      autoComplete: 'off',
      onChange: this.onChange,
    };

    return (
      <div
        className={`${styles.autocomplete} ${className} ${corePresent ? styles.corePresent : ''}`}
      >
        <label
          className={`${styles.wrapper} ${error ? styles.error : ''} ${
            corePresent ? styles.cpWrapper : ''
          }`}
        >
          {required && <span className={styles.required} />}
          {/* {label && <span className={styles.label}>{label}</span>} */}
          <Autosuggest
            suggestions={suggestions}
            onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
            onSuggestionsClearRequested={this.onSuggestionsClearRequested}
            getSuggestionValue={getSuggestionValue}
            renderSuggestion={renderSuggestion}
            inputProps={inputProps}
            theme={{
              ...theme,
              input: inputClasses,
            }}
            disabled={disabled}
            renderInputComponent={props => <Input label={label} required={required} {...props} />}
          />
        </label>

        {error && <span className={`${styles.errorMsg} ${styles.error}`}>{error}</span>}
      </div>
    );
  }
}

export { Autocomplete };
