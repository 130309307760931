import { ReactNode } from 'react';
import styled from 'styled-components';

interface Props {
  icon: ReactNode;
  title: string;
  description: string;
}

export const CriteriaReviewItem = ({ title, icon, description }: Props) => {
  return (
    <Container>
      <IconWrapper>{icon}</IconWrapper>
      <DetailsContainer>
        <Title>{title}</Title>
        <Description>{description}</Description>
      </DetailsContainer>
    </Container>
  );
};

const Container = styled.div`
  padding: 10px 25px;
  display: flex;
  gap: 20px;
  align-items: center;
  &:not(:last-of-type) {
    border-bottom: 1px solid #fff;
  }
`;

const IconWrapper = styled.div``;

const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Title = styled.div`
  font-size: 12px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.gray[400]};
  white-space: nowrap;
  overflow: hidden;
`;

const Description = styled.div`
  font-size: 12px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.gray[400]};
  text-align: center;
  margin-top: 2px;
`;
