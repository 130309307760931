import React, { useEffect } from 'react';
import { Polygon, Circle } from '@react-google-maps/api';

import { Markers } from './Markers';
import TopControls from './TopControls';
import BottomControls from './BottomControls';
import Loader from '../../../../../Loader/Loader';
import { IZipData, TSlideColors } from '../../../../../../types';
import { useUser } from '../../../../providers/UserProvider';
import { TNumberOfBuyersLookingForHomes } from '../../../../api/dummyData';
import { useLoadSlideData } from '../../../../../../hooks/useLoadSlideData';
import { useScaleFactor } from '../../../../../PreviewSlide/ScaleFactorProvider';
import { GoogleMapComponent } from '../../../../../Common/GoogleMap/GoogleMap.component';
import { useUiConfigColorsPrimary } from '../../../../../../providers/providers/UiConfigColorProvider';
import { useSlides } from '../../../../../../providers/providers/SlidesProvider';

const mapContainerStyle = {
  height: '100%',
  width: '100%',
};

const options = {
  fillColor: '#001',
  fillOpacity: 1,
  strokeColor: '#001',
  strokeOpacity: 1,
  strokeWeight: 2,
  clickable: false,
  draggable: false,
  editable: false,
  geodesic: false,
  zIndex: 1,
};

const adaptOptionsOpacity = (
  popularity: TNumberOfBuyersLookingForHomes,
  primary: string,
): typeof options => {
  const opt = { ...options };

  opt.fillColor = primary;
  opt.strokeColor = primary;

  switch (popularity) {
    case 'very_low':
      opt.fillOpacity = 0.1;
      opt.strokeOpacity = 0.1;
      break;
    case 'low':
      opt.fillOpacity = 0.2;
      opt.strokeOpacity = 0.2;
      break;
    case 'medium':
      opt.fillOpacity = 0.4;
      opt.strokeOpacity = 0.4;
      break;
    case 'high':
      opt.fillOpacity = 0.6;
      opt.strokeOpacity = 0.6;
      break;
    case 'very_high':
      opt.fillOpacity = 0.8;
      opt.strokeOpacity = 0.8;
      break;
  }
  return opt;
};

interface Props {
  zipData?: IZipData[] | null;
  properties?: any[];
  subject: any;
  zoomLevel: number;
  searchCriteria: any;
}

const Map: React.FC<Props> = ({ zipData = [], properties, subject, zoomLevel, searchCriteria }) => {
  const { isLoading, data } = useLoadSlideData('buyerDemand');
  const { setSlides, getSlide } = useSlides();
  const slide = getSlide('buyerDemand');

  const showSellerSupply = slide?.data?.sellerSupplyOn;

  const scaleFactor = useScaleFactor();
  const { isCanadaUser } = useUser()!;

  const getRadius = () => {
    const radiusFactor = isCanadaUser ? 0.621371 : 1;
    return searchCriteria.radius.value * 1609.34 * radiusFactor;
  };

  useEffect(() => {
    if (!isLoading && data && slide) {
      const updatedSlide = { ...slide };
      if (!updatedSlide.data) return;
      updatedSlide.data.mapData = data;
      setSlides(prev => ({ ...prev, buyerDemand: updatedSlide }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isLoading]);

  const primaryColor = useUiConfigColorsPrimary();

  if (isLoading) return <Loader size='4x' />;

  const activeComparables = properties
    ? properties.filter(p => !p.partialMatch && !p.excluded)
    : [];
  const activeNonComparables = properties
    ? properties.filter(p => p.partialMatch || p.excluded)
    : [];

  const getScaleFactor = () => {
    if (zoomLevel) {
      return zoomLevel;
    }
    return scaleFactor && scaleFactor < 0.16 ? 10 : 12;
  };

  const drawShape = () => {
    const { mapMode, mapCoordinates } = searchCriteria;

    const shapeOptions = {
      strokeColor: '#3BCA46',
      fillColor: '#3BCA46',
      fillOpacity: 0.1,
    };

    if (mapMode === 'polygon') {
      const coordinatesData = mapCoordinates ? JSON.parse(mapCoordinates) : null;
      const coordinates =
        coordinatesData &&
        coordinatesData.coordinates[0].map((coordinate: any) => ({
          lng: coordinate[0],
          lat: coordinate[1],
        }));
      return <Polygon paths={coordinates} options={shapeOptions} />;
    }

    if (mapMode === 'marker' || mapMode == null) {
      return (
        <Circle
          center={{
            lat: subject.coordinates.latitude,
            lng: subject.coordinates.longitude,
          }}
          radius={getRadius()}
          options={shapeOptions}
        />
      );
    }
  };

  if (!subject) {
    return <p>There is no data for this slide</p>;
  }

  return (
    <GoogleMapComponent
      mapContainerStyle={mapContainerStyle}
      center={{
        lat: subject.coordinates.latitude,
        lng: subject.coordinates.longitude,
      }}
      zoom={getScaleFactor()}
      zoomLevel={zoomLevel}
      scaleFactor={scaleFactor}
    >
      {zipData?.map((zip, i) => (
        <Polygon
          key={i}
          paths={zip.coordinates}
          options={adaptOptionsOpacity(zip.opacity, primaryColor)}
        />
      ))}
      {showSellerSupply && drawShape()}
      <Markers
        showSellerSupply={showSellerSupply}
        activeComparables={activeComparables}
        activeNonComparables={activeNonComparables}
        subject={subject}
      />
      {showSellerSupply && <TopControls />}
      <BottomControls />
    </GoogleMapComponent>
  );
};
export default React.memo(Map);
