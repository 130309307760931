import { useSetAtom } from 'jotai';

import { subjectAtom } from '../state/subjectAtom';
import { reportPropertiesAtom } from '../state/reportPropertiesAtom';
import { isPresentationViewAtom } from '../state/isPresentationViewAtom';
import { searchCriteriaAtom } from '../state/searchCriteriaAtom';
import { highlightedPropertiesAtom } from '../state/highlighted-properties/highlightedPropertiesAtom';
import { excludedPropertyIdsAtom } from '../state/excludedPropertyIdsAtom';
import { propertiesNotesAtom } from '../state/propertiesNotesAtom';
import { pricingFavorabilityAtom } from '../state/pricingFavorabilityAtom';
import { pricingRateOfChangeAtom } from '../state/pricingRateOfChangeAtom';
import { sellingPaceAtom } from '../state/sellingPaceAtom';
import { activeShelfLifeAtom } from '../state/activeShelfLifeAtom';
import { compAveragesAtom } from '../state/compAveragesAtom';
import { estimatesAtom } from '../state/estimatesAtom';
import { selectedCategoriesAtom } from '../state/selectedCategoriesAtom';
import { flagsAtom } from '../state/flagsAtom';
import { customMarkersAtom } from '../state/customMarkersAtom';
import { customEstimatesAtom } from '../state/customEstimatesAtom';
import { competitionGraphAtom } from '../state/competitionGraphAtom';
import { isDtcAtom } from '../state/isDtcAtom';

export const useSetAtomState = () => {
  const setSubject = useSetAtom(subjectAtom);
  const setProperties = useSetAtom(reportPropertiesAtom);
  const setIsPresentationView = useSetAtom(isPresentationViewAtom);
  const setSearchCriteria = useSetAtom(searchCriteriaAtom);
  const setHighlightedProperties = useSetAtom(highlightedPropertiesAtom);
  const setExcluded = useSetAtom(excludedPropertyIdsAtom);
  const setNotes = useSetAtom(propertiesNotesAtom);
  const setPricingFavorability = useSetAtom(pricingFavorabilityAtom);
  const setRateOfChange = useSetAtom(pricingRateOfChangeAtom);
  const setSellingPace = useSetAtom(sellingPaceAtom);
  const setActiveShelfLife = useSetAtom(activeShelfLifeAtom);
  const setCompAverages = useSetAtom(compAveragesAtom);
  const setEstimates = useSetAtom(estimatesAtom);
  const setSelectedCategories = useSetAtom(selectedCategoriesAtom);
  const setFlags = useSetAtom(flagsAtom);
  const setCustomMarkers = useSetAtom(customMarkersAtom);
  const setCustomEstimates = useSetAtom(customEstimatesAtom);
  const setCompetitionGraphData = useSetAtom(competitionGraphAtom);
  const setIsDtc = useSetAtom(isDtcAtom);

  return {
    setSubject,
    setProperties,
    setIsPresentationView,
    setSearchCriteria,
    setHighlightedProperties,
    setExcluded,
    setNotes,
    setPricingFavorability,
    setRateOfChange,
    setSellingPace,
    setActiveShelfLife,
    setCompAverages,
    setEstimates,
    setSelectedCategories,
    setFlags,
    setCustomMarkers,
    setCustomEstimates,
    setCompetitionGraphData,
    setIsDtc,
  };
};
