import styled from 'styled-components';
import { HeaderCard } from '../components/HeaderCard';
import { Button } from '../../../../components/Common/V2/Button/Button';
import { useHistory } from 'react-router-dom';
import { useUser } from '../../../../components/Slide/providers/UserProvider';
import { URLS } from '../../../../pages/PresentationCreate/dash/view/constants/URLS';
import { CriteriaReviewItem } from './CriteriaReviewItem';
import AreaSizeIcon from './assets/AreaSizeIcon.svg';
import BedroomIcon from './assets/BedroomIcon.svg';
import { useAtomValue } from 'jotai';
import { searchCriteriaAtom } from '../../state/searchCriteriaAtom';
import { useMemo } from 'react';
import { NumberFormatter } from '../../../../pages/PresentationCreate/dash/services/numberFormatter';
import { searchAPIAtom } from '../../state/searchAPIAtom';
import { searchCriteria } from '../../../../pages/PresentationCreate/dash/store/search/reducers';

export const ComparableCriteriaReview = () => {
  const history = useHistory();
  const { isCanadaUser } = useUser();

  const searchApi = useAtomValue(searchAPIAtom);

  const onEdit = () => {
    const params = new URLSearchParams(history.location.search);

    const p = params.toString();

    const URL = `${URLS.SEARCH.DETAILS}${!p.length ? '' : '?' + p}`;

    history.push(URL, {
      from: history.location.pathname + history.location.search,
    });
  };

  const size = useMemo(() => {
    if (!searchApi) return '';
    return `${NumberFormatter.format(searchApi?.size_from)} - ${NumberFormatter.format(
      searchApi?.size_to,
    )}`;
  }, [searchApi]);

  const beds = useMemo(() => {
    if (!searchApi) return '';
    return searchApi?.bed_from === searchApi?.bed_to
      ? `${searchApi?.bed_to}`
      : `${searchApi?.bed_from} - ${searchApi?.bed_to}`;
  }, [searchApi]);

  return (
    <HeaderCard title='Comparable Criteria Review'>
      <Container>
        <DescriptionContainer>
          <Description>
            To be considered a “comparable result” for the analysis. Properties must, at a minimum,
            match the following criteria:
          </Description>
          <Button variant='primary' onClick={onEdit}>
            Edit Search Criteria
          </Button>
        </DescriptionContainer>
        <CompCriteriaCardsWrapper>
          {!isCanadaUser && (
            <CriteriaReviewItem
              title='Living Area Size'
              description={size}
              icon={<AreaSizeIcon />}
            />
          )}
          <CriteriaReviewItem title='Bedroom Count' description={beds} icon={<BedroomIcon />} />
        </CompCriteriaCardsWrapper>
      </Container>
    </HeaderCard>
  );
};

const Container = styled.div`
  display: flex;
  gap: 20px;
  padding: 0 15px;
  align-items: center;
  justify-content: space-between;

  @media all and (max-width: 1199px) {
    flex-direction: column;
    justify-content: flex-start;
  }
`;

const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 53%;

  @media all and (max-width: 1199px) {
    width: 100%;
  }
`;

const Description = styled.p`
  font-size: 12px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.gray[400]};
  margin-bottom: 10px;
  text-align: center;
  padding: 0 5px;
`;

const CompCriteriaCardsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 172px;
  background-color: ${({ theme }) => theme.colors.gray[100]};
  border-radius: 5px;
`;
