import React from 'react';
import styled from 'styled-components';

import { withHeight } from '../withHeight';
import { ViewOnlyMarkerPolylineMap } from './ViewOnlyMarkerPolylineMap';
import { useUiConfigColorsPrimary } from '../../../../../../providers/providers/UiConfigColorProvider';
import { useAtomValue } from 'jotai';
import { reportPropertiesAtom } from '../../../../../../features/report/state/reportPropertiesAtom';
import { extractPropertyGroups } from '../../../../../../features/report/services/extractPropertiesGroups';
import { subjectAtom } from '../../../../../../features/report/state/subjectAtom';
import { searchCriteriaAtom } from '../../../../../../features/report/state/searchCriteriaAtom';

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

export const ComparativeMarketAnalysis = withHeight(({ height, isPdf = false }) => {
  const subject = useAtomValue(subjectAtom);
  const searchCriteria = useAtomValue(searchCriteriaAtom);

  const center = subject.property.coordinates;
  const { mapMode, radius, mapCoordinates } = searchCriteria;
  const primary = useUiConfigColorsPrimary();
  const propertiesRaw = useAtomValue(reportPropertiesAtom);
  const propertyGroups = extractPropertyGroups(propertiesRaw);

  return (
    <Container height={height}>
      <ViewOnlyMarkerPolylineMap
        defaultCenter={{ lat: center.latitude, lng: center.longitude }}
        subjectSize={subject.property.size}
        properties={propertyGroups}
        currentRadius={radius.value}
        mapMode={mapMode}
        color={primary}
        initCoordinates={mapCoordinates}
        style={{
          height: `${height}px`,
          width: `100%`,
        }}
        isPdf={isPdf}
      />
    </Container>
  );
});
