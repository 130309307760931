import React from 'react';
import styled from 'styled-components';
import {
  useUiConfigColorsPrimary,
  useUiConfigColorsSecondary,
} from '../../../../../providers/providers/UiConfigColorProvider';
import { TSlideId } from '../../../../../types';
import { SwitchButton_V2 } from '../../../../Common/SwitchButton/SwitchButton.component';
import { ColorOpacityApplier } from '../../../services/ColorOpacityApplier';
import Map from './Map/Map';
import { useAllSearchCriteria } from '../../../../../pages/PresentationCreate/dash/ReportFacade/data/useSearchCriterias';
import { useSubjectInfo } from '../../../../../pages/PresentationCreate/dash/ReportFacade/data/useSubjectInfo';
import {
  useUiConfigBodyLetterSpacing,
  useUiConfigFontsBody,
  useUiConfigFontsHeader,
  useUiConfigHeaderLetterSpacing,
} from '../../../../../providers/providers/UiConfigFontProvider';
import { DataValidation } from './Map/DataValidation';
import { useSlides } from '../../../../../providers/providers/SlidesProvider';

/* eslint-disable */
const Container = styled('div')({
  display: 'flex',
  width: '100%',
  height: '100%',
  padding: '2.5%',
});

const Col = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  borderRadius: 5,
  marginRight: 24,
  '&:last-child': {
    flex: 1,
    height: '100%',
    marginRight: 0,
  },
});

const MapWrapper = styled('div')({
  borderRadius: 5,
  display: 'flex',
  height: '100%',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: '#dddddd',
  overflow: 'hidden',
});

const DataValidationWrapper = styled('div')({
  width: '45%',
  margin: '10px auto 0 auto',
});

const Heading = styled('p')(({}) => ({
  fontWeight: 900,
  color: useUiConfigColorsPrimary(),
  textTransform: 'uppercase',
  fontSize: 20,
  fontFamily: useUiConfigFontsHeader(),
  letterSpacing: useUiConfigHeaderLetterSpacing(),
}));

const ColoredDiv = styled('div')(({}) => ({
  backgroundColor: ColorOpacityApplier.hex(useUiConfigColorsSecondary(), '0.1'),
  padding: 16,
  marginBottom: 30,
  borderRadius: 4.8,
  fontSize: 18,
  color: '#000',
  lineHeight: '32px',
  fontFamily: useUiConfigFontsBody(),
  letterSpacing: useUiConfigBodyLetterSpacing(),
}));

const Text = styled('p')(() => ({
  fontSize: 18,
  color: '#000',
  lineHeight: '32px',
  fontFamily: useUiConfigFontsBody(),
  letterSpacing: useUiConfigBodyLetterSpacing(),
  marginTop: 15,
}));

const SwitchCommandsWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
});

const SwitchItemWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
});

const SwitchButtonWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height: 56,
  border: '2px solid #f2f2f2',
  borderRadius: 4.8,
});

interface Props {
  title: string;
  onChange: (val: boolean) => void;
  checked: boolean;
}

const SwitchItem: React.FC<Props> = ({ title, onChange, checked }) => {
  const primary = useUiConfigColorsPrimary();
  return (
    <SwitchItemWrapper>
      <Heading style={{ color: primary, marginBottom: 8 }}>{title}</Heading>
      <SwitchButtonWrapper>
        Off <SwitchButton_V2 onChange={() => onChange(!checked)} checked={checked} /> On
      </SwitchButtonWrapper>
    </SwitchItemWrapper>
  );
};

const BuyerDemand: React.FC = () => {
  const { setSlideData, getSlide } = useSlides();
  const slide = getSlide('buyerDemand');

  const onSwitch = (key: 'sellerSupplyOn' | 'featuresOfInterestOn', val: boolean) => {
    const slideData = slide?.data;
    if (!slideData || !slide) return;
    setSlideData(slide.id as TSlideId, { [key]: val });
  };

  const searchCriteria = useAllSearchCriteria();
  const subject = useSubjectInfo();

  const sellerSupplyOn = slide?.data?.sellerSupplyOn;

  const zipData = slide?.buyerDemandData?.zipData;
  const properties = slide?.buyerDemandData?.properties;

  return (
    <Container>
      <Col style={{ width: 300 }}>
        <ColoredDiv>
          <Heading style={{ marginBottom: 2 }}>Buyer Demand</Heading>
          The law of supply and demand dictates the equilibrium price of a property, and I can show
          you if buyers are searching in your area now.
        </ColoredDiv>
        <SwitchCommandsWrapper>
          <SwitchItem
            title='Comparable Supply'
            onChange={val => onSwitch('sellerSupplyOn', !sellerSupplyOn)}
            checked={!!sellerSupplyOn}
          />
          <Text>
            To help put the demand in perspective, I'll layer in the supply of comparable properties
            within a close proximity & properties that just miss the mark for being considered a
            comparable.
          </Text>
        </SwitchCommandsWrapper>
      </Col>
      <Col style={{ overflow: 'hidden' }}>
        <MapWrapper>
          <Map
            subject={subject}
            zoomLevel={12}
            zipData={zipData}
            properties={properties}
            searchCriteria={searchCriteria}
          />
        </MapWrapper>
        <DataValidationWrapper>
          <DataValidation />
        </DataValidationWrapper>
      </Col>
    </Container>
  );
};

export default BuyerDemand;
