import { getPropertyStatus } from '../../../pages/PresentationCreate/dash/view/services/getPropertyStatus';
import { PropertyGroups, TProperty } from '../../../types';
import { getPropertyId } from './getPropertyId';

type TPropertyWithLabel = TProperty & { label?: string };

const getStatusIndicator = (status: string) => getPropertyStatus(status);

const getHighlightData = (property: TPropertyWithLabel) => {
  const {
    address,
    price,
    pricePerSqft,
    salePrice,
    salePricePerSqft,
    status,
    label = '',
  } = property;
  const priceToReturn = status === 'sold' ? salePrice : price;
  const pricePerSqftToReturn = status === 'sold' ? salePricePerSqft : pricePerSqft;

  return {
    id: getPropertyId(property),
    label,
    price: priceToReturn,
    pricePerSqft: pricePerSqftToReturn,
    name: address.deliveryLine,
    beds: property.beds,
    size: property.size,
    distance: property.distance,
    status: getStatusIndicator(status),
  };
};

const findHighlightProperty = (
  properties: TProperty[] | null | undefined,
  excluded: string[],
  sortingFunc: (a: TProperty, b: TProperty) => number,
  label: string,
) => {
  if (!properties) return;

  const excludedMap: Record<string, string> =
    excluded?.reduce((map, pId) => ({ ...map, [pId]: pId }), {}) ?? {};
  const nonExcludedProperties = properties?.filter(
    property => !excludedMap[getPropertyId(property)],
  );

  const [firstProperty, ..._] = nonExcludedProperties.sort(sortingFunc);

  const highlight = !firstProperty ? null : getHighlightData({ label, ...firstProperty });

  return { highlight };
};

export const getDefaultHighlights = (
  properties: PropertyGroups,
  result: { excluded: string[] },
) => {
  const mostRecentActive = findHighlightProperty(
    properties.active,
    result.excluded || [],
    (a, b) => b.listDate - a.listDate,
    'Most Recent',
  );
  const mostRecentSold = findHighlightProperty(
    properties.sold,
    result.excluded || [],
    (a, b) => b.saleDate - a.saleDate,
    'Most Recent',
  );
  const closestActive = findHighlightProperty(
    properties.active,
    result.excluded || [],
    (a, b) => a.distance - b.distance,
    'Closest Active',
  );
  const closestSold = findHighlightProperty(
    properties.sold,
    result.excluded || [],
    (a, b) => a.distance - b.distance,
    'Closest Sold',
  );
  const highestActive = findHighlightProperty(
    properties.active,
    result.excluded || [],
    (a, b) => b.price - a.price,
    'Highest Active',
  );
  const highestSold = findHighlightProperty(
    properties.sold,
    result.excluded || [],
    (a, b) => b.salePrice - a.salePrice,
    'Highest Sold',
  );

  const forHighlighting: any[] = [
    mostRecentActive?.highlight,
    mostRecentSold?.highlight,
    closestActive?.highlight,
    closestSold?.highlight,
    highestActive?.highlight,
    highestSold?.highlight,
  ]
    .filter(property => !!property)
    .filter(
      property =>
        !result.excluded.includes(getPropertyId(property as { mlsListingId?: string; id: string })),
    );
  forHighlighting.sort((a, b) => a.price - b.price);

  return {
    highlights: forHighlighting,
  };
};
