import React, { Component } from 'react';
import styled from 'styled-components';
import NumberFormat from 'react-number-format';

import states from '../../../../../../data/states';
import { Map } from '../../../../../components/Map/Map';
import classes from './PropertyInfoManualEntry.module.scss';
import { NextStepsFooter } from '../../components/NextStepsFooter/NextStepsFooter';
import { CurrentUserService } from '../../../../../../../../../services/CurrentUserService';
import { mapStyles } from '../../PropertyArea/AdrSelection/DrawableMarkerPolylineMap/MapStyles';
import { AddressCommunicator } from '../../../../../../communicators/Address/AddressCommunicator';
import { Input } from '../../../../../../../../../components/Common/V2/Input';
import { Select } from '../../../../../../../../../components/Common';

export class PropertyInfoManualEntry extends Component {
  constructor(props) {
    super(props);

    this.state = {
      propertyType: { value: 'SINGLE_FAMILY', label: 'Single Family' },
      status: 'STANDARD',
      beds: undefined,
      address: undefined,
      number: ' ',
      state: undefined,
      city: undefined,
      baths: 1,
      size: undefined,
      totalSize: undefined,
      yearBuilt: undefined,
      coordinates: undefined,
      loading: false,
      addressLocateLoading: false,
      errors: {},
    };
    this.completeManualProperty = this.completeManualProperty.bind(this);
    this.onFieldChange = this.onFieldChange.bind(this);
    this.setMarkerFromAddress = this.setMarkerFromAddress.bind(this);
  }

  statesList = states.map(({ name, abbreviation }) => {
    return { value: abbreviation, label: name };
  });

  onFieldChange(field) {
    return value => {
      const errors = { ...this.state.errors };
      if (value < 0 || isNaN(value)) {
        errors[field] = 'Invalid value';
      } else {
        delete errors[field];
      }
      this.setState({ [field]: value, errors });
    };
  }

  everythingPopulated() {
    const fields = Object.keys(this.state);
    const nonRequired = ['number', 'yearBuilt', 'totalSize', 'state'];
    if (CurrentUserService.isCanadaUser()) {
      nonRequired.push('size');
    }
    const nonPopulatedFields = fields.filter(
      field =>
        !nonRequired.includes(field) &&
        (this.state[field] === '' || this.state[field] === undefined || this.state.errors[field]),
    );
    return nonPopulatedFields.length === 0;
  }

  setMarker = marker => {
    this.marker = marker;
  };

  map = null;
  oms = null;
  google = null;
  bounds = null;
  defaultCenter = { lat: 36.778259, lng: -119.417931 };
  initial = true;

  createMarker(position, map, oms) {
    if (!position?.lat || !position?.lng || !map) return;

    const marker = new this.google.maps.Marker({
      position,
      map,
    });
    if (this.marker) {
      this.marker.setMap(null);
    }

    this.setMarker(marker);
    if (oms) {
      oms.addMarker(marker);
    }

    this.setState({ coordinates: position });
    this.bounds.extend(this.marker.getPosition());
    this.map.fitBounds(this.bounds);
  }

  componentDidMount() {
    const { address, city, state, number, beds, size } = this.props.address;

    this.setState({
      address,
      city: city === undefined ? '' : city,
      state: state,
      number: number === undefined ? '' : number,
      beds: isNaN(parseInt(beds)) ? '' : beds,
      size: !size ? '' : size,
    });
  }

  setMarkerFromAddress() {
    const { address, city, number, state } = this.state;
    this.setState({ addressLocateLoading: true });
    AddressCommunicator.fetchGeocode(address, number, city, state.value).then(response => {
      if (response.status === 'OK') {
        const marker = response.results[0].geometry.location;
        this.createMarker(marker, this.map, this.oms);
        const errors = { ...this.state.errors };
        delete errors['locate'];
        this.setState({ addressLocateLoading: false, errors });
      }
      if (response.status === 'ZERO_RESULTS') {
        const errors = { ...this.state.errors };
        errors['locate'] = 'Could not find coordinates for that address';
        this.setState({ addressLocateLoading: false, errors });
      }
    });
  }

  async completeManualProperty() {
    const { address, number, state, city, beds, coordinates, size, totalSize, yearBuilt } =
      this.state;

    this.setState({ loading: true });
    const addressData = { address, number, state, city };
    const searchCriteria = await AddressCommunicator.create(
      address,
      number,
      city,
      state.value,
      beds,
      size,
      totalSize,
      coordinates.lat,
      coordinates.lng,
      yearBuilt,
    );

    await this.props.onContinue(addressData, searchCriteria);
    this.setState({ loading: false });
  }

  render() {
    const { address, number, state, city, beds, size, yearBuilt, errors, loading } = this.state;

    if (!address && !city && !state) {
      return null;
    }
    const isCanada = CurrentUserService.isCanadaUser();

    return (
      <div className={classes.manualEntry}>
        <p className={classes.noDataText}>
          Title data was not found and property info will need to be entered manually to proceed. An
          incident report is being sent to our product team to help ensure that this is not a
          recurring issue for you.
          <br />
          <br />
          We apologize for the inconvenience.
        </p>

        <div>
          <Title>What is the address</Title>
          <Wrapper>
            <Grid style={{ gridTemplateColumns: '2fr 1fr' }}>
              <Input
                label='Address:'
                name='address'
                value={address}
                onChange={e => this.onFieldChange('address')(e.target.value)}
                error={errors.address}
                dataAttributes={{ 'data-test-address-manual': true }}
              />

              <Input
                label='Apt or Suite #:'
                name='apartment_number'
                value={number}
                onChange={e => this.onFieldChange('number')(e.target.value)}
                error={errors.number}
                dataAttributes={{ 'data-test-apartment-input': true }}
              />
            </Grid>

            <Grid style={{ gridTemplateColumns: isCanada ? '1fr' : '1fr 1fr' }}>
              <Input
                label={isCanada ? 'Province' : 'City'}
                name='city'
                value={city}
                onChange={e => this.onFieldChange('city')(e.target.value)}
                error={errors.city}
                dataAttributes={{ 'data-test-city-input': true }}
              />
              {!isCanada && (
                <div style={{ position: 'relative' }}>
                  <Select
                    label='State'
                    placeholder='Options'
                    defaultValue={state && [state]}
                    options={this.statesList}
                    onChange={e => this.onFieldChange('state')(e)}
                    dataAttributes={{ 'data-test-state-select': true }}
                  />
                  {errors.state && <ErrorText>{errors.state}</ErrorText>}
                </div>
              )}
            </Grid>

            <Grid>
              <Input
                type='number'
                label='Beds'
                name='beds'
                value={beds}
                onChange={e => this.onFieldChange('beds')(e.target.value)}
                error={errors.beds}
                dataAttributes={{ 'data-test-beds-input': true }}
              />

              <NumberFormat
                customInput={Input}
                label='Living Area Sqft'
                name='size'
                value={size}
                placeholder={isCanada ? 'Optional' : ''}
                onValueChange={({ floatValue }) => this.onFieldChange('size')(floatValue)}
                error={errors.size}
                dataAttributes={{ 'data-test-size-input': true }}
                thousandSeparator={true}
              />
              <Input
                type='number'
                label='Year Built'
                name='yearBuilt'
                value={yearBuilt}
                onChange={e => this.onFieldChange('yearBuilt')(e.target.value)}
                error={errors.yearBuilt}
                dataAttributes={{ 'data-test-size-input': true }}
              />
            </Grid>

            <Map
              id={`myMap}`}
              options={{
                center: this.defaultCenter,
                zoom: 16,
                styles: mapStyles,
                disableDefaultUI: true,
              }}
              onMapLoad={(map, google, oms) => {
                this.map = map;
                this.google = google;
                this.oms = oms;
                this.initial = true;
                this.setMarkerFromAddress();

                google.maps.event.addListener(this.map, 'click', e => {
                  this.createMarker(
                    {
                      lat: e.latLng.lat(),
                      lng: e.latLng.lng(),
                    },
                    this.map,
                    this.oms,
                  );
                });

                google.maps.event.addListener(map, 'zoom_changed', () => {
                  const zoomChangeBoundsListener = google.maps.event.addListener(
                    map,
                    'bounds_changed',
                    () => {
                      if (map.getZoom() > 15 && this.initial === true) {
                        // Change max/min zoom here
                        map.setZoom(15);
                        this.initial = false;
                      }
                      google.maps.event.removeListener(zoomChangeBoundsListener);
                    },
                  );
                });

                map.initialZoom = true;
                const bounds = new google.maps.LatLngBounds();
                this.bounds = bounds;
                map.fitBounds(bounds);
              }}
              style={{ height: `500px`, width: `100%` }}
            />
            {errors.locate && <LocateError>{errors.locate}</LocateError>}
          </Wrapper>
        </div>

        <NextStepsFooter
          onPrev={this.props.onBack}
          onNext={this.completeManualProperty}
          disabled={!this.everythingPopulated() || loading || !!this.state.errors.yearBuilt}
          loading={loading}
          className={classes.footer}
        />
      </div>
    );
  }
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 10px;
`;

const Title = styled.h3`
  color: ${props => props.theme.colors.v2.gray[400]};
  font-family: Figtree;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 10px;
`;

const ErrorText = styled.span`
  position: absolute;
  bottom: -14px;
  left: 0px;
  font-size: 11px;
  color: ${props => props.theme.colors.v2.status.error};
`;

const LocateError = styled.span`
  font-family: Figtree;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;
