import { useAtomValue } from 'jotai';
import styled from 'styled-components';

import { TProperty } from '../../../types';
import { SectionDetailItem } from './components/SectionDetailItem';
import { CurrentUserService } from '../../../services/CurrentUserService';
import { searchCriteriaAtom } from '../../../features/report/state/searchCriteriaAtom';
import { MoneyFormatter } from '../../../pages/PresentationCreate/dash/services/moneyFormatter';
import { NumberFormatter } from '../../../pages/PresentationCreate/dash/services/numberFormatter';
import { getPropertyTypeText } from '../../../pages/PresentationCreate/dash/view/services/getPropertyTypeText';
import { SectionDropdown } from './components/SectionDropdown';
import { DropdownKeys } from '../../../features/report/components/Property/DetailDescription/DetailDescription';

const getPropertyDistance = (property: TProperty) =>
  CurrentUserService.isCanadaUser()
    ? `${property?.distanceKm} km away`
    : `${property?.distance} mi away`;

interface Props {
  property: TProperty;
  openedDropdown: string;
  setOpenedDropdown: (value: string) => void;
}

export const ComparableDetails = ({ property, openedDropdown, setOpenedDropdown }: Props) => {
  const searchCriteria = useAtomValue(searchCriteriaAtom);

  const getSizeDiffString = (field: 'size' | 'totalSize' = 'size') => {
    return parseInt(String(property[field])) - parseInt(String(searchCriteria?.[field])) > 0
      ? `${NumberFormatter.format(
          parseInt(String(property[field])) - parseInt(String(searchCriteria?.[field])),
        )} sqft larger`
      : `${NumberFormatter.format(
          parseInt(String(searchCriteria?.[field])) - parseInt(String(property[field])),
        )} sqft smaller`;
  };
  return (
    <SectionDropdown
      title={'Comparable Details'.toUpperCase()}
      isOpen={openedDropdown === DropdownKeys.COMPARABLE_DETAILS}
      setOpen={() => {
        if (openedDropdown === DropdownKeys.COMPARABLE_DETAILS) return setOpenedDropdown('');
        setOpenedDropdown(DropdownKeys.COMPARABLE_DETAILS);
      }}
    >
      <Wrapper>
        <SectionDetailItem label='Distance' value={getPropertyDistance(property)} />
        <SectionDetailItem label='Comp Type' value={getPropertyTypeText(property.status)} />
        <SectionDetailItem label='Year' value={String(property.yearBuilt)} />
        <SectionDetailItem label='Bed Count' value={String(property.beds)} />
        <SectionDetailItem
          label='Bath Count'
          value={`${property.totalBaths} (Full: ${property.baths})`}
        />
        <SectionDetailItem
          hide={!property.size}
          label='Living Area Sqft'
          value={`${NumberFormatter.format(property.size)} (${getSizeDiffString()})`}
        />
        <SectionDetailItem
          hide={!property.totalSize}
          label='Full Lot Sqft'
          value={`${NumberFormatter.format(property.totalSize)} (${getSizeDiffString(
            'totalSize',
          )})`}
        />
        <SectionDetailItem label='Basement' value={property.basement ?? 'No info'} />
        <SectionDetailItem label='Garages' value={String(property.garage)} />
        {property.hoaFee && (
          <SectionDetailItem
            label='Association Fees'
            value={`${MoneyFormatter.format(property.hoaFeeValue)} (${property.hoaFeeFreq})`}
          />
        )}
      </Wrapper>
    </SectionDropdown>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
