import { useEffect, useMemo, useRef } from 'react';

import { useAtom, useAtomValue } from 'jotai';
import { filteredPropertiesAtom } from '../../../state/filteredPropertiesAtom';
import { reportPropertiesAtom } from '../../../state/reportPropertiesAtom';
import styled from 'styled-components';
import { useUser } from '../../../../../components/Slide/providers/UserProvider';
import { ShowPropertiesOnMap } from './ShowPropertiesOnMap';
import { HomesGroup } from './HomesGroup/HomesGroup';
import { extractPropertyGroups } from '../../../services/extractPropertiesGroups';
import { PROPERTY_STATUS } from '../../../../../types';

export const PropertiesList = () => {
  const { isCanadaUser } = useUser();
  const [filteredProperties, setFilteredProperties] = useAtom(filteredPropertiesAtom);
  const properties = useAtomValue(reportPropertiesAtom);

  const theProperties = filteredProperties || properties;

  const numberOfAllProperties = useMemo(() => {
    return theProperties?.filter(p => !p.excluded)?.length;
  }, [theProperties]);

  const propertyGroups = useMemo(() => extractPropertyGroups(theProperties), [theProperties]);

  useEffect(() => {
    return () => {
      if (filteredProperties) setFilteredProperties(null);
    };
  }, [filteredProperties, setFilteredProperties]);

  const ref = useRef<HTMLElement | null>(null);

  return (
    <Container ref={ref}>
      <Content>
        <Info>
          <TotalResults>{numberOfAllProperties} TOTAL RESULTS</TotalResults>

          <ShowPropertiesOnMap />
        </Info>

        <HomesGroup
          title='COMING SOON HOMES'
          type='coming_soon'
          hide={!propertyGroups.comingSoon?.length}
          properties={propertyGroups?.comingSoon || []}
          propertiesRef={ref}
        />

        <HomesGroup
          title='ACTIVE HOMES'
          type={PROPERTY_STATUS.ACTIVE}
          hide={!propertyGroups.active?.length}
          properties={propertyGroups?.active || []}
          propertiesRef={ref}
        />

        {!isCanadaUser && (
          <HomesGroup
            title='PENDING HOMES'
            type={PROPERTY_STATUS.PENDING}
            hide={!propertyGroups.pending?.length}
            properties={propertyGroups?.pending || []}
            propertiesRef={ref}
          />
        )}

        <HomesGroup
          title='CLOSED HOMES'
          type={PROPERTY_STATUS.SOLD}
          hide={!propertyGroups.sold?.length}
          properties={propertyGroups?.sold || []}
          propertiesRef={ref}
        />
      </Content>
    </Container>
  );
};

const Container = styled.section`
  padding: 0 20px 20px 0;
  height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  width: 400px;

  @media all and (max-width: 992px) {
    width: 100%;
    text-align: center;
    border: none;
  }
`;

const Content = styled.div`
  flex-grow: 1;
  position: relative;
`;

const Info = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ededed;
  color: #373e43;
  text-transform: uppercase;
  padding: 0 10px;
  font-size: 13px;
  position: sticky;
  height: 30px;
  top: 0;
  z-index: 1;
`;

const TotalResults = styled.div`
  margin: 0;
  font-weight: 700;
  font-size: 12px;
  color: ${({ theme }) => theme.colors.gray[400]};
`;
