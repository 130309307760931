import { connect } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

import styles from './PropertyAddress.module.scss';
import { URLS } from '../../../../constants/URLS';
import { operations } from '../../../../../store/search';
import { searchCriteria } from '../../../../../store/search/reducers';
import { PropertyInfoForm } from './PropertyInfoForm/PropertyInfoForm';
import { operations as reportOperations } from '../../../../../store/report';
import { Timestamp } from '../../../../../../../../services/Timestamp';
import { StepLeftSection } from '../components/StepLeftSection/StepLeftSection';
import { PropertyInfoOverride } from './PropertyInfoOverride/PropertyInfoOverride';
import { PropertyInfoSection } from '../components/PropertyInfoSection/PropertyInfoSection';
import { PropertyInfoManualEntry } from './PropertyInfoManualEntry/PropertyInfoManualEntry';

export const PropertyAddressComponent = ({
  onContinue,
  setAddress,
  setSearchCriteria,
  setRebuildData,
  resetSearch,
  className = '',
  loading,
  setLoading,
  resetReport,
}) => {
  const [isNonExistentAddress, setIsNonExistentAddress] = useState(null);
  const [overridePropertyStep, setOverridePropertyStep] = useState(false);

  const location = useLocation();
  const history = useHistory();
  const query = new URLSearchParams(location.search);

  useEffect(() => {
    if (query.has('new')) {
      resetSearch();
      resetReport();
      setOverridePropertyStep(false);
      setIsNonExistentAddress(false);

      history.push(URLS.SEARCH.ADDRESS + `?${query.get('type')}`);
    }

    if (query.has('nonExistent') && isNonExistentAddress === null) {
      setIsNonExistentAddress({
        address: query.get('address'),
        city: query.get('city'),
        number: query.get('number'),
        state: query.get('state'),
      });
    }
  });

  useEffect(
    function onStepTwo() {
      const params = new URLSearchParams();
      if (overridePropertyStep) {
        params.append('step', 'info');
      } else {
        params.delete('step');
      }
      if (query.has('type')) params.append('type', query.get('type'));
      history.push({ search: params.toString() });
    },
    [history, overridePropertyStep],
  );

  const setInitialConfig = (address, property) => {
    setSearchCriteria({});
    const searchCriteria = {
      id: property.id,
      propertyType: property.propertyType,
      status: property.saleType,
      beds: property.beds,
      baths: property.baths,
      size: property.size,
      totalSize: property.totalSize,
      yearBuilt: property.yearBuilt,
      coordinates: property.coordinates,
      totalSizeSet: false,
      reportCreatedAt: Timestamp.unix(),
    };

    setAddress(address);
    setSearchCriteria(searchCriteria);
    setRebuildData({});

    return searchCriteria;
  };

  const onInitialConfigSubmit = (address, property) => {
    setInitialConfig(address, property);
    setOverridePropertyStep(true);
  };

  const overrideInitialConfig = async partiallyUpdatedSearchCriteria => {
    const updatedSC = {
      ...searchCriteria,
      ...partiallyUpdatedSearchCriteria,
    };

    setSearchCriteria(updatedSC);

    onContinue(updatedSC);
  };

  const startBuildingReport = async (address, property) => {
    const searchCriteria = setInitialConfig(address, property);

    return onContinue(searchCriteria);
  };

  const onPrevious = () => {
    setOverridePropertyStep(false);
  };

  return (
    <div className={`${styles.propertyAddress} ${className}`}>
      <StepLeftSection>
        <PropertyInfoSection
          className={styles.info}
          title='PRICE A PROPERTY'
          description='Enter the address for the property you would like to price. Once entered, we will use MLS data to provide accurate neighborhood comps.'
          hint='You can edit all of the comp criteria on the next step.'
        />
      </StepLeftSection>

      <div className={styles.rightSection}>
        {!isNonExistentAddress ? (
          <>
            <PropertyInfoForm
              disableFooter={overridePropertyStep}
              onContinue={onInitialConfigSubmit}
              onNoAddress={setIsNonExistentAddress}
            />
            {overridePropertyStep && (
              <PropertyInfoOverride
                onPrevious={onPrevious}
                onContinue={overrideInitialConfig}
                loading={loading}
                setLoading={setLoading}
              />
            )}
          </>
        ) : (
          <PropertyInfoManualEntry
            address={isNonExistentAddress}
            onBack={() => setIsNonExistentAddress(null)}
            onContinue={startBuildingReport}
          />
        )}
      </div>
    </div>
  );
};

const dispatchToProps = dispatch => ({
  setAddress: address => dispatch(operations.setAddress(address)),
  setSearchCriteria: searchCriteria => dispatch(operations.setSearchCriteria(searchCriteria)),
  resetSearch: () => dispatch(operations.resetSearch()),
  setRebuildData: data => dispatch(reportOperations.setRebuildData(data)),
  resetReport: () => dispatch(reportOperations.resetReport()),
});

export const PropertyAddress = connect(null, dispatchToProps)(PropertyAddressComponent);
