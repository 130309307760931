import { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { propertyTypeOptions } from './propertyTypeOptions';
import { SearchCombiner } from '../../../../SearchCombiner';
import { SearchValidator } from '../../../../SearchValidator';
import { PropertyTypeEdit } from './PropertyTypeEdit/PropertyTypeEdit';
import { PropertyTypeSelector } from './PropertyTypeSelector/PropertyTypeSelector';
import { CriteriaOptionsSubtitle } from '../components/CriteriaOptionsSubtitle/CriteriaOptionsSubtitle';

import styles from './PropertyType.module.scss';
import { CRITERIAS } from '../../../Criterias';
import { useCriteriaIsOpen } from '../../../CriteriaIsOpenProvider';
import { CriteriaAccordion } from '../components/CriteriaAccordion';

const info =
  'Data is directly pulled from PropertyModal records for the subject. Only comps that match the subject are selected as the default. It is recommended to limit comp results to only matching property types. ';

export const PropertyTypeComponent = ({ searchCriteria, onChange }) => {
  const [propertyType, setPropertyType] = useState(null);
  const [propertyTypeDefault, setPropertyTypeDefault] = useState(null);
  const [propertyTypeSelections, setPropertyTypeSelections] = useState([]);
  const [isOpen] = useCriteriaIsOpen(CRITERIAS.PROPERTY_TYPE);

  const validate = () => propertyTypeSelections && propertyTypeSelections.length;

  const onCombine = () => ({
    propertyType: [propertyType],
    propertyTypeInit: [propertyTypeDefault],
    propertyTypeSelections,
  });

  const onEdit = val => {
    if (val.value !== propertyType.value) {
      setPropertyType(val);
      setPropertyTypeSelections([val]);
    }
  };

  const getPropertyType = propType => {
    if (typeof propType === 'string') {
      return propertyTypeOptions.find(type => type.value === propType);
    }

    if (Array.isArray(propType) && propType.length) {
      return propertyTypeOptions.find(type => type.value === propType?.[0]?.value);
    }

    return { ...propertyTypeOptions[0] };
  };
  const getInitPropertyTypeSelections = () => {
    const { propertyTypeSelections } = searchCriteria;
    if (!propertyTypeSelections) {
      const v =
        propertyTypeOptions.find(type => type.value === searchCriteria.propertyType) ||
        propertyTypeOptions[0];
      return [{ ...v }];
    }

    return propertyTypeSelections;
  };

  useEffect(() => {
    const initPropType = getPropertyType(searchCriteria.propertyTypeInit);
    const propType = getPropertyType(searchCriteria.propertyType);
    const initPropTypeSelections = getInitPropertyTypeSelections();

    setPropertyTypeDefault(initPropType);
    setPropertyType(propType);
    setPropertyTypeSelections(initPropTypeSelections);
  }, []);

  useEffect(() => {
    SearchValidator.subscribe('propertyType', validate);
    SearchCombiner.add('propertyType', onCombine);
    onChange(SearchCombiner.combine());
    return () => {
      SearchValidator.unsubscribe('propertyType');
      SearchCombiner.remove('propertyType');
    };
  }, [propertyType, propertyTypeSelections, propertyTypeDefault]);

  if (!propertyType) {
    return null;
  }

  return (
    <CriteriaAccordion title='PROPERTY TYPE' info={info} isOpen={isOpen}>
      <PropertyTypeEdit
        defaultValue={propertyTypeDefault}
        subject={propertyType}
        onSubjectChange={onEdit}
      />

      <CriteriaOptionsSubtitle />

      <PropertyTypeSelector
        className={styles.selections}
        selected={propertyTypeSelections}
        onChange={setPropertyTypeSelections}
      />
    </CriteriaAccordion>
  );
};

const stateToProps = ({ search }) => ({
  searchCriteria: search.searchCriteria,
});

export const PropertyType = connect(stateToProps)(PropertyTypeComponent);
