import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { UserSettings } from '../../types';
import { corePresentApi } from '../../api/CorePresentApi';
import { useUrlSearchParam } from '../../hooks/useQueryParams';
import { StorageService } from '../../services/storageService';

const fetchUserSettings = async (
  domain: string | null,
  agentId: string | null,
): Promise<UserSettings | undefined> => {
  return await corePresentApi.get(`/accountDetail/whitelabelSettings`, {}, { domain, agentId });
};

export function useDomainWhitelabelSettings(
  forcedAgentId?: number,
  forcedDomain?: string,
): UseQueryResult<UserSettings> {
  const agentIdSearchParam = useUrlSearchParam('agentId');
  const agentId = forcedAgentId || StorageService.read('agentId') || agentIdSearchParam;

  const domain = forcedDomain || window.location.hostname;

  return useQuery(['userSettings', domain, agentId], () => fetchUserSettings(domain, agentId), {
    refetchOnWindowFocus: false,
    staleTime: Infinity,
    enabled: Boolean(domain) && Boolean(agentId),
    retry: 1,
  });
}
