import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ReactSelect from 'react-select';

import styles from './Select.module.scss';

const getStyles = (hasError = false, isSolid = false, fontSize, isMulti) => ({
  control: styles => ({
    display: 'flex',
    fontSize: fontSize,
  }),
  valueContainer: styles => ({
    ...styles,
    padding: '0 6px',
    color: '#626262',
    fontWeight: 'normal',
    justifyContent: 'initial',
  }),
  dropdownIndicator: () =>
    isMulti
      ? { display: 'none' }
      : { color: '#626262', position: 'absolute', bottom: '0', right: '4px' },
  indicatorSeparator: () => ({ display: 'none' }),
  menu: styles => ({
    ...styles,
    fontWeight: 'normal',
    fontSize: fontSize,
    marginTop: '12px',
    borderTop: 'none',
    borderRadius: '0 0 3px 3px',
  }),
  singleValue: styles => ({ ...styles, color: '#373e43', marginTop: '1px' }),
  option: (provided, state) => ({
    ...provided,
    color: '#626262',
    padding: '8px 5px',
    backgroundColor: state.isFocused ? '#e6e6e6' : '#fff',
    cursor: 'pointer',
    fontSize: '14px',
  }),
});

class Select extends PureComponent {
  static propTypes = {
    options: PropTypes.array,
    onChange: PropTypes.func,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    className: PropTypes.string,
    error: PropTypes.string,
    compact: PropTypes.bool,
    solid: PropTypes.bool,
    isMulti: PropTypes.bool,
    fontSize: PropTypes.string,
    required: PropTypes.bool,
    noError: PropTypes.bool,
    disabled: PropTypes.bool,
    fill: PropTypes.bool,
  };

  static defaultProps = {
    value: {},
    options: [],
    onChange: f => f,
    label: '',
    placeholder: '',
    className: '',
    error: '',
    compact: false,
    solid: false,
    isMulti: false,
    fontSize: '1em',
    required: false,
    noError: false,
    disabled: false,
    fill: false,
  };

  render() {
    const {
      value,
      options,
      placeholder,
      className,
      onChange,
      error,
      solid,
      isMulti,
      fontSize,
      required,
      disabled,
      noError,
    } = this.props;
    const valueToPass = () => {
      if (value === undefined || value === null) {
        return value;
      }
      return Object.keys(value).length === 0 ? null : value;
    };
    return (
      <div className={`${styles.select} ${className}`}>
        <label
          className={`${styles.wrapper} ${disabled ? styles.disabled : ''} ${
            error ? styles.error : ''
          }`}
        >
          {required && <span className={styles.required} />}
          <ReactSelect
            value={valueToPass()}
            placeholder={placeholder}
            isDisabled={disabled}
            options={options}
            styles={getStyles(error, solid, fontSize, isMulti)}
            onChange={onChange}
            isMulti={isMulti}
          />
        </label>

        {!noError && <span className={styles.errorMsg}>{error}</span>}
      </div>
    );
  }
}

export { Select };
