import React, { useState } from 'react';
import styled from 'styled-components';

import HeroBanner from './HeroBanner/HeroBanner';
import DesktopCreatePresentationSwiper from './CreatePresentation/DesktopCreatePresentationSwiper';
import MobileCreatePresentationSwiper from './CreatePresentation/MobileCreatePresentationSwiper';
import { NotifyMlsEmailPopup } from '../../../../components/Common/NotifyMlsPopup/NotifyMlsEmailPopup.component';
import { useUser } from '../../../../components/Slide/providers/UserProvider';

const HomeHeaderSection: React.FC = () => {
  const { feedIds } = useUser();
  const userHasFeeds = feedIds?.length > 0;

  return (
    <HomeHeaderSectionWrapper>
      <HeroBanner />
      {!userHasFeeds && <NotifyMlsEmailPopup />}
      <DesktopCreatePresentationSwiper />
      <MobileCreatePresentationSwiper />
    </HomeHeaderSectionWrapper>
  );
};

export default HomeHeaderSection;

const HomeHeaderSectionWrapper = styled.div`
  background: ${({ theme }) => theme.colors.v2.gradient.background};
  padding: 37px 0px 23px;
  justify-content: center;
`;
