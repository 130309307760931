import React, { CSSProperties, ChangeEvent, FormEvent, useEffect, useState } from 'react';
import styled from 'styled-components';
import Autosuggest, { Theme } from 'react-autosuggest';
import { corePresentApi } from '../../../api/CorePresentApi';
import { TClient } from '../../../types';
import { Input } from '../V2/Input';

export const ErrorMessage = styled('p')(({ theme }) => ({
  fontSize: 14,
  color: theme.colors.error,
  marginTop: 5,
}));

export const SuggestionItem = styled.div`
  padding: 10px;
  cursor: pointer;
  border-radius: 5px;
  &:hover {
    background-color: ${({ theme }) => theme.colors.v2.gray[100]};
  }
`;

const getAutocompleteStyles = (
  hasError: boolean,
  suggestionsContainerStyles?: CSSProperties,
): Theme => {
  return {
    container: {
      position: 'relative',
      width: '100%',
    },
    inputFocused: {
      outline: 'none',
    },
    suggestionsContainer: {
      fontSize: 14,
      boxShadow: '0px 11px 40px 0 rgba(214, 214, 214, 0.11)',
      background: '#fff',
      maxHeight: 180,
      overflowY: 'auto',
      top: 'calc(100% + 1px)',
      borderRadius: '5px',
      fontWeight: 500,
      ...suggestionsContainerStyles,
    },
    suggestionsContainerOpen: {
      border: '1px solid #ddd',
      padding: '20px',
    },
    suggestionsList: {
      listStyleType: 'none',
    },
  };
};

export type TClientOption = {
  email: string;
  id: number | string;
  name: string;
  label: string;
  value: number;
  autoComplete: string;
  onChange: (event: FormEvent<HTMLElement>, params: ChangeEvent) => void;
};

interface AutosuggestClientsProps {
  setClient: (client: TClient | null) => void;
  initialValue?: string;
  placeholder?: string;
  suggestionsContainerStyles?: CSSProperties;
}

const getSuggestionValue = (suggestion: any) => suggestion.label;
const renderSuggestion = (suggestion: any) => <SuggestionItem>{suggestion.label}</SuggestionItem>;

export const AutosuggestClients: React.FC<AutosuggestClientsProps> = ({
  setClient,
  initialValue,
  placeholder,
  suggestionsContainerStyles = {},
}) => {
  const [clientOptions, setClientOptions] = useState<TClientOption[]>([]);
  const [value, setValue] = useState(initialValue ?? '');
  const [error, setError] = useState('');

  useEffect(() => {
    if (!initialValue) return;
    setValue(initialValue);
    setError('');
  }, [initialValue]);

  const getSuggestions = (options: TClientOption[], value: string) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    return inputLength === 0 ? [] : options;
  };

  const getClientOptions = async (value: any) => {
    const val = value.value;
    try {
      const data = await corePresentApi.get(`/clients?query=${val}`);

      const autocompleteClients = data.map((c: TClient) => ({
        ...c,
        label: c.name,
        value: c.id,
      }));

      setClientOptions(getSuggestions(autocompleteClients, val));
      setError(!data.length ? 'Client not found' : '');
      return autocompleteClients;
    } catch (e: any) {
      console.error(e);
    }
  };

  const onSuggestionSelected = (
    event: FormEvent,
    { suggestion }: { suggestion: TClientOption },
  ) => {
    setClient(suggestion as TClient);
  };

  const onChange = (event: FormEvent, { newValue }: { newValue: string }) => {
    setValue(newValue);
  };

  const inputProps = {
    value: `${value}`,
    autoComplete: 'off',
    onChange: onChange,
    placeholder: placeholder ? placeholder : '',
  };

  return (
    <>
      <Autosuggest
        suggestions={clientOptions}
        onSuggestionsFetchRequested={getClientOptions}
        onSuggestionsClearRequested={() => {
          setClientOptions([]);
        }}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        inputProps={inputProps}
        theme={getAutocompleteStyles(!!error, suggestionsContainerStyles)}
        onSuggestionSelected={onSuggestionSelected}
        renderInputComponent={inputProps => {
          return <Input {...inputProps} inputWrapperStyle={{ width: '100%' }} />;
        }}
      />
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </>
  );
};
