import { Circle, DrawingManager, Polygon } from '@react-google-maps/api';
import { GoogleMapComponent } from '../../../../components/Common/GoogleMap/GoogleMap.component';
import { PROPERTY_STATUS, PropertyGroups, TProperty } from '../../../../types';
import { PropertyMarker } from './PropertyMarker';
import { SubjectMarker } from './SubjectMarker';
import { CurrentUserService } from '../../../../services/CurrentUserService';
import { useTheme } from 'styled-components';
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { mapStyles } from '../../../../pages/PresentationCreate/dash/view/pages/Authenticated/PropertySearch/PropertyArea/AdrSelection/DrawableMarkerPolylineMap/MapStyles';
import { getPropertyId } from '../../services/getPropertyId';
import { useAtom } from 'jotai';
import { selectedPropertyAtom } from '../../state/selectedPropertyAtom';

interface Props {
  defaultCenter: any;
  subject: TProperty;
  properties: PropertyGroups;
  currentRadius: number;
  mapMode: google.maps.drawing.OverlayType | null;
  setMapMode: React.Dispatch<React.SetStateAction<string>>;
  updateCoordinates: (coordinates: any) => void;
  initCoordinates: any;
  onAdd: (comp: string) => void;
  onExclude: (comp: string) => void;
  setIsModalOpen: Dispatch<SetStateAction<boolean>>;
}

const isInCircle = (property: TProperty, circle: google.maps.Circle) => {
  const markerPosition = property.coordinates;
  if (!markerPosition) return false;

  return circle
    ?.getBounds()
    ?.contains({ lat: markerPosition.latitude, lng: markerPosition.longitude });
};

const isInPolygon = (property: TProperty, polygon: google.maps.Polygon | null) => {
  const markerPosition = property.coordinates;
  if (!markerPosition) return false;

  return (
    polygon &&
    google.maps?.geometry?.poly?.containsLocation(
      { lat: markerPosition.latitude, lng: markerPosition.longitude },
      polygon,
    )
  );
};

export const InteractiveMap = ({
  defaultCenter,
  subject,
  properties,
  currentRadius,
  mapMode,
  setMapMode,
  updateCoordinates,
  initCoordinates,
  onAdd,
  onExclude,
  setIsModalOpen,
}: Props) => {
  const { colors } = useTheme();
  const getRadiusFactor = () => {
    return CurrentUserService.isCanadaUser() ? 0.621371 : 1;
  };

  const [circle, setCircle] = useState<google.maps.Circle | null>(null);
  const [polygon, setPolygon] = useState<google.maps.Polygon | null>(null);
  const [, setSelectedProperty] = useAtom(selectedPropertyAtom);

  const [mapInstance, setMapInstance] = useState<google.maps.Map | null>(null);

  const propertiesList = useMemo(() => {
    return [
      ...(properties?.comingSoon || []),
      ...(properties.active || []),
      ...(properties.pending || []),
      ...(properties.sold || []),
    ];
  }, [properties.active, properties?.comingSoon, properties.pending, properties.sold]);

  const onCloseInfoWindow = () => {
    document.dispatchEvent(new CustomEvent('close-info-window'));
  };

  useEffect(() => {
    const onMoreInfo = ({
      id,
      status,
    }: {
      id: string;
      status: PROPERTY_STATUS | 'coming_soon';
    }) => {
      onCloseInfoWindow();
      const propertyType = status === 'coming_soon' ? 'comingSoon' : (status as PROPERTY_STATUS);

      const selectedProperty = properties?.[propertyType]?.find(
        property => getPropertyId(property) === id,
      );

      setSelectedProperty(selectedProperty || null);
      setIsModalOpen(true);
    };

    document.addEventListener('more-info', (e: Event) => {
      onMoreInfo((e as CustomEvent).detail);
    });

    return () => {
      document.removeEventListener('more-info', (e: Event) => {
        onMoreInfo((e as CustomEvent).detail);
      });
    };
  }, [properties, setIsModalOpen, setSelectedProperty]);

  useEffect(() => {
    const onAddComp = (propertyId: string) => {
      onCloseInfoWindow();
      onAdd(propertyId);
    };

    const onExcludeComp = (propertyId: string) => {
      onCloseInfoWindow();
      onExclude(propertyId);
    };

    document.addEventListener('add-comp', (e: Event) => {
      onAddComp((e as CustomEvent).detail);
    });

    document.addEventListener('remove-comp', (e: Event) => {
      onExcludeComp((e as CustomEvent).detail);
    });

    return () => {
      document.removeEventListener('add-comp', (e: Event) => {
        onAddComp((e as CustomEvent).detail);
      });

      document.removeEventListener('remove-comp', (e: Event) => {
        onExcludeComp((e as CustomEvent).detail);
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fitBoundsToMarkers = () => {
      if (!mapInstance) return;

      const bounds = new window.google.maps.LatLngBounds();

      const isInShape = (property: TProperty) => {
        return circle ? isInCircle(property, circle) : isInPolygon(property, polygon);
      };

      const isAllIn = propertiesList.every(property => isInShape(property));

      if (!isAllIn) {
        if (propertiesList?.length && subject) {
          [...propertiesList, subject].forEach(property => {
            bounds.extend({
              lat: property.coordinates.latitude,
              lng: property.coordinates.longitude,
            });
          });

          mapInstance.fitBounds(bounds);
          return;
        }
      }

      if (circle) {
        const bounds = circle?.getBounds();
        bounds && mapInstance.fitBounds(bounds);
        return;
      }

      if (polygon) {
        const paths = polygon.getPaths();
        paths.forEach(function (path) {
          const ar = path.getArray();
          for (let i = 0, l = ar.length; i < l; i++) {
            bounds.extend(ar[i]);
          }
        });

        bounds && mapInstance.fitBounds(bounds);
        return;
      }
    };

    fitBoundsToMarkers();
  }, [circle, mapInstance, polygon, properties, propertiesList, subject]);

  const circleOptions = {
    strokeColor: colors.v2.primary,
    strokeOpacity: 1.0,
    strokeWeight: 2,
    fillColor: colors.v2.primary,
    fillOpacity: 0.2,
  };

  const polygonOptions = {
    strokeColor: colors.v2.primary,
    strokeOpacity: 1.0,
    strokeWeight: 2,
    fillColor: colors.v2.primary,
    fillOpacity: 0.2,
  };

  return (
    <GoogleMapComponent
      center={defaultCenter}
      zoom={14.25}
      mapContainerStyle={{
        height: `500px`,
        width: `100%`,
      }}
      setMapInstance={setMapInstance}
      options={{
        zoomControl: true,
        streetViewControl: true,
        fullscreenControl: true,
        mapTypeControl: true,
        disableDefaultUI: true,
        styles: mapStyles,
      }}
    >
      <DrawingManager
        onLoad={(drawingManager: google.maps.drawing.DrawingManager) => {
          google.maps.event.addListener(drawingManager, 'drawingmode_changed', () => {
            const drawingMode = drawingManager.getDrawingMode();
            setMapMode(drawingMode as string);
          });
        }}
        onOverlayComplete={e => {
          e.overlay?.setMap(null);
        }}
        onPolygonComplete={(polygon: google.maps.Polygon) => {
          const dataLayer = new google.maps.Data();
          dataLayer.add({
            geometry: new google.maps.Data.Polygon([polygon.getPath().getArray()]),
          });
          dataLayer.toGeoJson((obj: any) => {
            updateCoordinates(JSON.stringify(obj.features[0].geometry));
          });
        }}
        options={{
          drawingControl: true,
          drawingControlOptions: {
            position: google.maps.ControlPosition.TOP_CENTER,
            drawingModes: [google.maps.drawing.OverlayType.POLYGON],
          },
          drawingMode: mapMode,
          polygonOptions,
        }}
      />
      {(mapMode === 'marker' || mapMode == null) && typeof initCoordinates !== 'string' && (
        <Circle
          center={defaultCenter}
          radius={currentRadius * getRadiusFactor() * 1000 * 1.609}
          options={circleOptions}
          onLoad={c => setCircle(c)}
          onUnmount={() => {
            setCircle(null);
          }}
        />
      )}
      {(mapMode === 'polygon' || mapMode === null) && typeof initCoordinates === 'string' && (
        <Polygon
          paths={
            typeof initCoordinates === 'string' &&
            JSON.parse(initCoordinates).coordinates[0].map((latLng: any) => ({
              lat: latLng[1],
              lng: latLng[0],
            }))
          }
          onLoad={p => setPolygon(p)}
          options={polygonOptions}
          onUnmount={() => {
            setPolygon(null);
          }}
        />
      )}
      <SubjectMarker subject={subject} />
      {propertiesList?.map(property => {
        return <PropertyMarker property={property} key={property.id} subject={subject} />;
      })}
    </GoogleMapComponent>
  );
};
