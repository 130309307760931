import { TProperty } from '../../../../types';
import { getPropertyId } from '../../services/getPropertyId';
import { getPropertyStatus } from '../../../../pages/PresentationCreate/dash/view/services/getPropertyStatus';

export function propertyToMarker(p: TProperty, label = '') {
  const id = getPropertyId(p);
  const priceToReturn = p.status === 'sold' ? p.salePrice : p.price;
  const pricePerSqftToReturn = p.status === 'sold' ? p.salePricePerSqft : p.pricePerSqft;
  const adjustedPricePerSqft = !p.adjustedPrice ? null : Math.round(p.adjustedPrice / p.size);

  return {
    id,
    label,
    price: priceToReturn,
    pricePerSqft: pricePerSqftToReturn,
    name: p.address?.deliveryLine,
    beds: p.beds,
    size: p.size,
    distance: p.distance,
    status: getPropertyStatus(p.status),
    adjustedPrice: p.adjustedPrice ?? null,
    adjustedPricePerSqft: adjustedPricePerSqft ?? null,
  };
}
