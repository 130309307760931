import React, { useEffect, useMemo, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { connect } from 'react-redux';

import { Pills } from './Pills';
import classes from './AdrSection.module.scss';
import { radiusOptions } from './radiusOptions';
import { SearchCombiner } from '../../SearchCombiner';
import { Select } from '../../../../../components/Select/Select';
import { Button } from '../../../../../components/Button/Button';
import { LoadingOverlay } from './ManualReportMap/LoadingOverlay';
import { useUser } from '../../../../../../../../../components/Slide/providers/UserProvider';
import { useExtendBuildPropertiesWithAdjusted } from '../useExtendBuildPropertiesWithAdjusted';
import { InteractiveMap } from '../../../../../../../../../features/report/components/InteractiveMap/InteractiveMap';

const deepCopy = value => JSON.parse(JSON.stringify(value));

const AdrSectionComponent = ({
  coordinates,
  searchCriteria,
  searchData,
  partialLoading,
  partialData,
  onChange,
  exclusions,
  setExclusions,
  additional,
  setAdditional,
  getAdditional,
  getExclusions,
  excludeComp,
  addComp,
  setIsModalOpen,
}) => {
  const { isCanadaUser } = useUser();
  const unit = isCanadaUser ? ' km' : 'mile';

  const [mapKey, setMapKey] = useState(uuid());
  const [mapMode, setMapMode] = useState(null);
  const [initMapMode, setInitMapMode] = useState(null);
  const [radius, setRadius] = useState({ label: '1', value: 1 });
  const [initRadius, setInitRadius] = useState({ label: '1', value: 1 });
  const [mapCoordinates, setMapCoordinates] = useState([]);
  const [initMapCoordinates, setInitMapCoordinates] = useState([]);

  const properties = useExtendBuildPropertiesWithAdjusted(partialData.properties);

  const getMapCoordinates = propertyName => {
    if (searchCriteria[propertyName]) {
      return deepCopy(searchCriteria[propertyName]);
    }

    return [
      {
        lat: coordinates.latitude,
        lng: coordinates.longitude,
      },
    ];
  };

  const getRadius = propertyName => {
    if (searchCriteria[propertyName]) {
      const radius = deepCopy(searchCriteria[propertyName]);

      const newRadius = {
        label: `${radius.value} ${unit}`,
        value: radius.value,
      };

      return newRadius;
    }
    // this is where it takes the init value for radius from.
    return { label: `1 ${unit}`, value: 1 };
  };

  const getMapMode = propertyName => {
    if (!searchCriteria[propertyName] || searchCriteria[propertyName] === 'marker') return null;

    return searchCriteria[propertyName];
  };

  useEffect(() => {
    setMapCoordinates(getMapCoordinates('mapCoordinates'));
    setInitMapCoordinates(getMapCoordinates('initMapCoordinates'));
    setRadius(getRadius('radius'));
    setInitRadius(getRadius('initRadius'));
    setMapMode(getMapMode('mapMode'));
    setInitMapMode(getMapMode('initMapMode'));
    setExclusions(getExclusions('exclusions'));
    setAdditional(getAdditional('additional'));
  }, []);

  // // weird bug...reported by
  //   useEffect(() => {
  //     const newCoords = [
  //       {
  //         lat: coordinates.latitude,
  //         lng: coordinates.longitude,
  //       },
  //     ];
  //     setMapCoordinates(newCoords);
  //     setInitMapCoordinates(newCoords);
  //   }, [searchCriteria.id]);

  const onCombine = () => ({
    coordinates,
    mapCoordinates,
    initMapCoordinates,
    radius,
    initRadius,
    mapMode,
    initMapMode,
    additional,
    exclusions,
  });

  useEffect(() => {
    SearchCombiner.add('map', onCombine);
    return () => SearchCombiner.remove('map', onCombine);
  });

  const updateCoordinates = newCoordinates => {
    if (typeof newCoordinates == 'string') {
      if (newCoordinates !== coordinates) {
        // setMapCoordinates([]);
        setMapCoordinates(newCoordinates);
      }
      return;
    }
    const newCoords = newCoordinates[0];
    if (typeof mapCoordinates == 'string' && typeof newCoords != 'string') {
      setMapCoordinates(newCoordinates);
      return;
    }
    // weird, but okay!?
    if (newCoords.latitude !== mapCoordinates.lat || newCoords.longitude !== mapCoordinates.lng) {
      setMapCoordinates(newCoordinates);
    }
  };

  useEffect(() => {
    onChange(SearchCombiner.combine());
  }, [mapCoordinates, radius, exclusions, additional]);

  const restartMap = () => setMapKey(uuid);

  const defaultCenter = {
    lat: coordinates.latitude,
    lng: coordinates.longitude,
  };

  const mapOptions = radiusOptions.map(option => {
    return {
      ...option,
      label: `${option.label} ${unit}`,
    };
  });

  return (
    <div className={classes.adrSection}>
      {coordinates.latitude && mapCoordinates.length && (
        <>
          <Pills searchData={searchData} />
          {/*{partialLoading && <><Loading extraSmall /> Properties are loading...</>}*/}
          <LoadingOverlay loading={partialLoading}>
            <div className={`${classes.mapContainer}`}>
              {!partialLoading && (
                <div className={classes.mapButtonsWrapper}>
                  <Button
                    text='Reset'
                    onClick={() => {
                      setMapCoordinates(deepCopy(initMapCoordinates));
                      setRadius(deepCopy(initRadius));
                      setMapMode(initMapMode);

                      restartMap();
                    }}
                    className={classes.resetMapButton}
                  />

                  <Select
                    placeholder='Radius'
                    value={radius ? radius : { label: '1', value: 1 }}
                    onChange={setRadius}
                    options={mapOptions}
                    className={classes.radius}
                    noError
                  />
                </div>
              )}

              <InteractiveMap
                defaultCenter={defaultCenter}
                subject={partialData?.subject}
                properties={properties}
                currentRadius={
                  (mapMode === 'marker' || mapMode == null) && radius ? radius.value : 1
                }
                mapMode={mapMode}
                initCoordinates={mapCoordinates}
                setMapMode={setMapMode}
                updateCoordinates={updateCoordinates}
                onAdd={addComp}
                onExclude={excludeComp}
                setIsModalOpen={setIsModalOpen}
              />
            </div>
          </LoadingOverlay>
        </>
      )}
    </div>
  );
};

const stateToProps = ({ search }) => {
  const { coordinates } = search.searchCriteria;

  return {
    searchCriteria: search.searchCriteria,
    coordinates,
  };
};
export const AdrSection = connect(stateToProps)(AdrSectionComponent);
