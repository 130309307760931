import React from 'react';
import styled, { CSSProperties } from 'styled-components';

import { Criteria, CriteriaBT } from './Criteria';
import { ColorOpacityApplier } from '../../../../../services/ColorOpacityApplier';
import {
  useUiConfigColorsPrimary,
  useUiConfigColorsSecondary,
} from '../../../../../../../providers/providers/UiConfigColorProvider';
import {
  useUiConfigBodyLetterSpacing,
  useUiConfigFontsHeader,
} from '../../../../../../../providers/providers/UiConfigFontProvider';
import { useUser } from '../../../../../providers/UserProvider';

interface ReviewBoxProps {
  isPdf?: boolean;
}

interface QuadrantProps {
  isCanadaUser?: boolean;
}

const ReviewBox = styled.div<ReviewBoxProps>`
  background: #ffffff;
  border: 1px solid #dddddd;
  border-radius: 5px;
  font-weight: 400;
  font-size: ${({ isPdf }) => (isPdf ? '14px' : '18px')};
  line-height: ${({ isPdf }) => (isPdf ? 1.3 : '34px')};
  text-align: left;
  flex: 1;
  display: flex;
  flex-direction: column;

  & > h3 {
    font-weight: 900;
    color: ${() => useUiConfigColorsPrimary()};
    font-size: ${({ isPdf }) => (isPdf ? '15px' : '20px')};
    line-height: ${({ isPdf }) => (isPdf ? '25px' : '34px')};
    text-transform: uppercase;
    padding-left: 15px;
    padding-top: 15px;
    font-family: ${() => useUiConfigFontsHeader()};
  }

  & > p {
    padding-left: 25px;
    padding-bottom: 20px;
    letter-spacing: ${() => useUiConfigBodyLetterSpacing()};
  }
`;

const Grid = styled.div`
  border-top: 1px solid #dddddd;
  display: grid;
  grid-template-columns: 50% 50%;
  grid-auto-rows: 50% 50%;
  /* height: 247px; */
  flex: 1;
  width: 100%;
  background: ${() => ColorOpacityApplier.hex(useUiConfigColorsSecondary(), '.1')};
`;

const Quadrant = styled.div<QuadrantProps>`
  display: flex;
  justify-content: center;
  align-items: center;

  grid-column: ${({ isCanadaUser }) => (isCanadaUser ? 'span 2' : 'span 1')};

  &:nth-child(1) {
    border-right: 1px solid #dddddd;
    border-bottom: 1px solid #dddddd;
  }
  &:nth-child(2) {
    border-bottom: 1px solid #dddddd;
  }
  &:nth-child(3) {
    border-right: 1px solid #dddddd;
  }
`;

interface Props {
  isPdf?: boolean;
  style?: CSSProperties;
  gridStyles?: CSSProperties;
  headingStyles?: CSSProperties;
}

export const CriteriaReviewBox: React.FC<Props> = ({
  isPdf = false,
  style,
  gridStyles,
  headingStyles,
}) => {
  const { isCanadaUser } = useUser();

  return (
    <ReviewBox isPdf={isPdf} style={style}>
      <h3 style={headingStyles}>Comparable Criteria Review</h3>
      <p>At a minimum, comparables must match the following criteria:</p>

      <Grid style={gridStyles}>
        {!isCanadaUser && (
          <Quadrant>
            <Criteria isPdf={isPdf} type={'size'} />
          </Quadrant>
        )}
        <Quadrant isCanadaUser={isCanadaUser}>
          <Criteria isPdf={isPdf} type={'bed'} />
        </Quadrant>
        <Quadrant>
          <Criteria isPdf={isPdf} type={'distance'} />
        </Quadrant>
        <Quadrant>
          <Criteria isPdf={isPdf} type={'date'} />
        </Quadrant>
      </Grid>
    </ReviewBox>
  );
};

export const CriteriaReviewBoxBT: React.FC<Props> = ({ isPdf = false, style }) => {
  return (
    <ReviewBox isPdf={isPdf} style={style}>
      <h3>Features of Tour</h3>
      <p>At a minimum, properties must match the following criteria:</p>

      <Grid>
        <Quadrant>
          <CriteriaBT isPdf={isPdf} type={'distance'} />
        </Quadrant>
        <Quadrant>
          <CriteriaBT isPdf={isPdf} type={'date'} />
        </Quadrant>
        <Quadrant>
          <CriteriaBT isPdf={isPdf} type={'size'} />
        </Quadrant>
        <Quadrant>
          <CriteriaBT isPdf={isPdf} type={'bed'} />
        </Quadrant>
      </Grid>
    </ReviewBox>
  );
};
