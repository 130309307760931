import { useAuthMe } from '../../hooks/useAuthMe';
import { StorageService } from '../../services/storageService';
import { useHasFeatureFlag, FEATURES } from '../../hooks/useFeatureFlags';
import { constructWhitelabelLoginUrl } from './constructWhitelabelLoginUrl';
import { useDomainWhitelabelSettings } from './useDomainWhitelabelSettings';

export function useRedirectToWhiteLabel() {
  const { data: me } = useAuthMe();
  const token = StorageService.read('token');

  const hasWhiteLabelFF = useHasFeatureFlag(FEATURES.BoldTrailWhiteLabel, false);
  const { data: wlSettings, isLoading: isLoadingWlSettings } = useDomainWhitelabelSettings(
    me?.kvcore_user_id,
    me?.website,
  );

  const currentHostname = window.location.hostname.startsWith('www.')
    ? window.location.hostname.slice(4)
    : window.location.hostname;

  if (
    !hasWhiteLabelFF ||
    !token ||
    !me?.website ||
    me?.website?.toLowerCase() === currentHostname ||
    isLoadingWlSettings ||
    !wlSettings?.whiteLabelSettings
  )
    return;

  window.location.href = constructWhitelabelLoginUrl(me.website, me.kvcore_user_id);
}
