import { Switch, Route, Redirect, useLocation } from 'react-router-dom';

import { AuthenticatedRoutes } from './Routes';
import { Chat } from '../../features/chat/Chat';
import { Library } from '../Library/Library.page';
import { Homepage } from '../Home/Home.page';
import { KvCoreReport } from '../KvCoreReport/KvCoreReport';
import { DashboardPage } from '../Dashboard/Dashboard.page';
import { PresentationCreator } from '../PresentationCreator/PresentationCreator';
import { AdminAnalytics } from '../AdminAnalytics/AdminAnalytics.page';
import { CustomUserPaths, CustomUserRouter } from './CustomUserRouter';
import { withLoadedUser } from '../../hoc/withLoadedUser';
import { PresentationEditPage } from '../PresentationEdit/PresentationEdit.page';
import { PresentationCreate } from '../PresentationCreate/PresentationCreate.page';
import { PresentationSettings } from '../PresentationSettings/PresentationSettings';
import { useIdentifyFullstory } from '../../features/fullstory/useIdentifyFullstory';
import { AgentAdminAnalytics } from '../AgentAdminAnalytics/AgentAdminAnalytics.page';
import { DelightedTracking } from '../../components/DelightedTracking/DelightedTracking.component';
import { UserProvider } from '../../components/Slide/providers/UserProvider';
import { withProviders } from '../../hoc/withProviders';
import { useRedirectToWhiteLabel } from '../../features/whitelabel/useRedirectToWhiteLabel';

const LogedInUserPaths = Object.values(AuthenticatedRoutes);

const LogedInUserRouter = withProviders([UserProvider, { fetch: true }])(
  withLoadedUser(function AuthenticatedRouter() {
    useIdentifyFullstory();

    return (
      <>
        <DelightedTracking />
        <Chat />

        <Switch>
          <Route path={AuthenticatedRoutes.settings} component={PresentationSettings} />
          {/* TODO: deprecate /create in favor of /presentation/new */}
          <Route path={AuthenticatedRoutes.create} component={PresentationCreate} />{' '}
          <Route path={AuthenticatedRoutes.presentationNew} component={PresentationCreator} />
          <Route path={AuthenticatedRoutes.presentationEdit} component={PresentationEditPage} />
          <Route path={AuthenticatedRoutes.presentationDashboard} component={DashboardPage} />
          <Route path={AuthenticatedRoutes.library} component={Library} />
          <Route path={AuthenticatedRoutes.home} component={Homepage} />
          <Route path={AuthenticatedRoutes.kvcoreReport} component={KvCoreReport} />
          <Route path={AuthenticatedRoutes.analyticsUserOffice} component={AgentAdminAnalytics} />
          <Route path={AuthenticatedRoutes.analyticsOffice} component={AdminAnalytics} />
          <Route path={AuthenticatedRoutes.analytics} component={AdminAnalytics} />
        </Switch>
      </>
    );
  }),
);

export function AuthenticatedRouter() {
  const { search } = useLocation();

  useRedirectToWhiteLabel();

  return (
    <Switch>
      <Route path={LogedInUserPaths} component={LogedInUserRouter} />
      <Route path={CustomUserPaths} component={CustomUserRouter} />

      <Redirect to={AuthenticatedRoutes.home + search} />
    </Switch>
  );
}
