import React, { useState } from 'react';
import styled from 'styled-components';

import { Icon } from '../../../../../components/Common';
import CardsWrapper from './CardsWrapper';
import { usePresentationCardsData } from './usePresentationCardsData';

const MobileCreatePresentationSwiper: React.FC = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [opacity, setOpacity] = useState(1);
  const { mobileData: data } = usePresentationCardsData();

  const handleNext = () => {
    setOpacity(0);
    setTimeout(() => {
      setCurrentIndex((currentIndex + 1) % data.length);
      setOpacity(1);
    }, 150);
  };

  const handlePrevious = () => {
    setOpacity(0);
    setTimeout(() => {
      setCurrentIndex((currentIndex - 1 + data.length) % data.length);
      setOpacity(1);
    }, 150);
  };

  return (
    <Container>
      <Icon
        iconName='arrow_left'
        onClick={handlePrevious}
        style={{
          position: 'absolute',
          left: 0,
          display: currentIndex !== 0 ? '' : 'none',
          cursor: 'pointer',
          zIndex: 1,
        }}
      />
      <CardsWrapper data={data[currentIndex]} style={{ opacity }} />
      <Icon
        iconName='arrow_right'
        onClick={handleNext}
        style={{
          position: 'absolute',
          right: 15,
          display: currentIndex !== data.length - 1 ? '' : 'none',
          cursor: 'pointer',
          zIndex: 1,
        }}
      />
    </Container>
  );
};

export default MobileCreatePresentationSwiper;

const Container = styled.div`
  display: none;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1550px;
  gap: 25px;
  margin: 0 auto;
  padding: 0 15px;

  @media (max-width: 980px) {
    display: flex;
  }
`;
