import React from 'react';
import styled from 'styled-components';
import {
  useUiConfigFontsHeader,
  useUiConfigBodyLetterSpacing,
} from '../../../../../../providers/providers/UiConfigFontProvider';
import { useUser } from '../../../../providers/UserProvider';
import { Criteria } from '../../../whatsMyHomeWorth/comparativeMarketAnalysis/components/CriteriaReviewBox/Criteria';

interface ReviewBoxProps {
  isPdf?: boolean;
}
const ReviewBox = styled.div<ReviewBoxProps>`
  background: #ffffff;
  border: 1px solid #dddddd;
  border-radius: 5px;
  font-weight: 400;
  font-size: ${({ isPdf }) => (isPdf ? '14px' : '18px')};
  line-height: ${({ isPdf }) => (isPdf ? '23px' : '34px')};
  text-align: left;
  flex: 1;
  display: flex;
  max-height: 120px;
  min-height: 120px;
`;

const Grid = styled.div`
  display: flex;
  flex: 1;
  background: #fff;
`;

const Quadrant = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  border-left: 1px solid #dddddd;
`;

const Col = styled.div`
  display: flex;
  flex-direction: column;
`;

export const H3 = styled.h3`
  font-weight: 900;
  color: #444;
  font-size: 18px;
  line-height: 25px;
  text-transform: uppercase;
  font-family: ${() => useUiConfigFontsHeader()};
  margin-bottom: 6px;
`;

const Text = styled.p`
  font-size: 16px;
  letter-spacing: ${() => useUiConfigBodyLetterSpacing()};
  line-height: 1.4;
`;

interface Props {
  isPdf?: boolean;
}

export const CriteriaReviewBox: React.FC<Props> = ({ isPdf = false }) => {
  const h3 = React.useRef<HTMLHeadingElement>(null);
  const h3Heigh = h3.current?.clientHeight ?? 0;

  const { isCanadaUser } = useUser();

  return (
    <ReviewBox isPdf={isPdf}>
      <Col style={{ width: 340, padding: 20, paddingRight: 20 }}>
        <H3 ref={h3} style={{ fontSize: h3Heigh > 20 ? 16 : 18 }}>
          Comparable Criteria Review
        </H3>
        <Text>At a minimum, comparables must match the following criteria:</Text>
      </Col>

      <Grid>
        {!isCanadaUser && (
          <Quadrant>
            <Criteria isPdf type={'size'} />
          </Quadrant>
        )}
        <Quadrant>
          <Criteria isPdf type={'bed'} />
        </Quadrant>
        <Quadrant>
          <Criteria isPdf type={'distance'} />
        </Quadrant>
        <Quadrant>
          <Criteria isPdf type={'date'} />
        </Quadrant>
      </Grid>
    </ReviewBox>
  );
};
