import React from 'react';
import styled from 'styled-components';
import { AccountOfficeDropdown } from '../../../../../../../components/Common/AccountOfficeDropdown/AccountOfficeDropdown.component';
import { FlexWrapper, InputWrapper, Label } from './styles';

const Col = styled('div')({
  display: 'flex',
  flexDirection: 'column',
});

interface Props {
  value: number | null;
  onChange: (val: number | null) => void;
}

export const AccountOfficeSelector: React.FC<Props> = ({ value, onChange }) => {
  const hasSecondarySelection = Boolean(value);
  const isSuper = value === -1 || value === -2;

  return (
    <FlexWrapper>
      <Col>
        <Label style={{ marginBottom: hasSecondarySelection ? 40 : 0 }}>Permissioned for</Label>
        {!hasSecondarySelection ? null : <Label>{isSuper ? 'Account' : 'Office'}</Label>}
      </Col>
      <InputWrapper>
        <AccountOfficeDropdown
          value={value}
          hidePresentationType
          orientation='vertical'
          onChange={onChange}
          fullWidth
        />
      </InputWrapper>
    </FlexWrapper>
  );
};
