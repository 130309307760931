import { Fragment, useState } from 'react';
import { useAtomValue } from 'jotai';
import styled, { useTheme } from 'styled-components';

import { TCategory } from '../../../../types';
import { useUiConfigColorsPrimary } from '../../../../providers/providers/UiConfigColorProvider';
import { Loading } from '../../../../pages/PresentationCreate/dash/view/components/Loading/Loading';
import { MoneyFormatter } from '../../../../pages/PresentationCreate/dash/services/moneyFormatter';
import { BasicModal } from '../../../../components/Common/BasicModal';
import { PricingFavorability } from '../../../../components/Slide/slides/marketAnalysis/marketOverview/components/pricingFavorability/web/PricingFavorability';
import { usePricingFavorabilityData } from '../../charts/pricing-favorability/usePricingFavorabilityData';
import { isPresentationViewAtom } from '../../state/isPresentationViewAtom';
import { MarketAssessmentCard } from './MarketAssessmentCard';
import bgImg from './assets/average-monthly-price-dark.svg?url';
import bgImgLight from './assets/average-monthly-price.svg?url';

interface PricingFavorabilityGaugeProps {
  trackClick?: () => void;
  category?: TCategory;
}

export const PricingFavorabilityGauge = ({
  trackClick,
  category,
}: PricingFavorabilityGaugeProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const { options, activeMonth } = usePricingFavorabilityData();
  const isPresentationView = useAtomValue(isPresentationViewAtom);
  const primaryColor = useUiConfigColorsPrimary();
  const { colors } = useTheme();

  if (options.length === 0) {
    return <Loading />;
  }

  const openPopup = () => {
    setIsOpen(true);
    trackClick?.();
  };

  const closePopup = () => {
    setIsOpen(false);
  };

  const color = isPresentationView ? primaryColor : colors.v2.primary;

  return (
    <Fragment>
      <MarketAssessmentCard
        headline='Average Monthly List/Sale Price'
        content={
          <PriceWrapper>
            <Price style={{ backgroundColor: color }}>
              {MoneyFormatter.format(options[activeMonth].price)}
            </Price>
          </PriceWrapper>
        }
        description='Average list price of all monthly active and pending comparables'
        bgImg={bgImg}
        bgImgLight={bgImgLight}
        clicked={openPopup}
      />

      <BasicModal id='marketOverview' isOpen={isOpen} onClose={closePopup}>
        <PricingFavorability category={category || 'traditional'} />
      </BasicModal>
    </Fragment>
  );
};

const PriceWrapper = styled.div`
  height: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const Price = styled.h2`
  font-size: 46px;
  font-weight: bold;
  text-align: center;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  color: transparent;
`;
