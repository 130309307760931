import React from 'react';
import styled from 'styled-components';

import { ColorOpacityApplier } from '../../../../services/ColorOpacityApplier';
import { useUiConfigFontsHeader } from '../../../../../../providers/providers/UiConfigFontProvider';
import { useUiConfigColorsSecondary } from '../../../../../../providers/providers/UiConfigColorProvider';
import { useSubjectInfo } from '../../../../../../pages/PresentationCreate/dash/ReportFacade/data/useSubjectInfo';
import { useProperties } from '../../../../../../providers/providers/PropertiesProvider';
import { getPriceData } from '../web/ReportInfoSection';
import { MoneyFormatter } from '../../../../../../pages/PresentationCreate/dash/services/moneyFormatter';
import { abbreviateNumber } from '../../../../../../services/abbreviateNumber';
import { useUser } from '../../../../providers/UserProvider';
import { useCreatedAt } from '../../../../../../providers/providers/CreatedAtProvider';
import { NumberFormatter } from '../../../../../../pages/PresentationCreate/dash/services/numberFormatter';

const Container = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 20px;
`;
const ColoredBox = styled.div`
  background: ${() => ColorOpacityApplier.hex(useUiConfigColorsSecondary(), '0.1')};
  border: 1px solid #dddddd;
  border-radius: 5px;
  padding: 43px 20px;
  flex-grow: 1;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const ColoredBoxTitle = styled.h3`
  text-align: center;
  line-height: 150%;
  font-weight: 800;
  font-size: 17px;
  text-transform: uppercase;
  font-family: ${() => useUiConfigFontsHeader()};
`;
const ColoredBoxSubtitle = styled.h4`
  text-align: center;
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  text-align: center;
  letter-spacing: 0.07em;
  color: #000000;
`;

const ColoredDetails = styled.h5`
  font-weight: 300;
  font-style: italic;
  padding-top: 10px;
  font-size: 13px;
  line-height: 15px;
  text-align: center;
  letter-spacing: 0.07em;
  color: #000000;
`;

export const ReportInfoSectionPdf: React.FC = () => {
  const { addressShort, address, beds, size } = useSubjectInfo();
  const { createdAt } = useCreatedAt();
  const { isCanadaUser } = useUser();

  return (
    <Container>
      <ColoredBox>
        <ColoredBoxTitle>
          {addressShort}
          <br />
          {`${address.city}, ${address.state} ${address.zip}`}
        </ColoredBoxTitle>
        <ColoredBoxSubtitle style={{ marginTop: 10 }}>
          {!isNaN(parseInt(String(beds))) && `${beds} Bedrooms `}
          {!isCanadaUser && !!size && `| Living Area Size ${NumberFormatter.format(size)} sqft`}
        </ColoredBoxSubtitle>
        <ColoredDetails>Report valid for {new Date(createdAt).toLocaleDateString()}</ColoredDetails>
      </ColoredBox>
    </Container>
  );
};

export const ReportInfoSectionBTPdf: React.FC = () => {
  const { properties } = useProperties();
  const { createdAt } = useCreatedAt();

  const priceData = getPriceData(properties);

  return (
    <Container>
      <ColoredBox>
        <ColoredBoxTitle>AVERAGE PRICE: {MoneyFormatter.format(priceData.avg)}</ColoredBoxTitle>
        <ColoredBoxSubtitle style={{ marginTop: 10 }}>
          Price range of tour:{' '}
          {`$${abbreviateNumber(priceData.min)} - $${abbreviateNumber(priceData.max)}`}
        </ColoredBoxSubtitle>
        <ColoredDetails>
          Report valid for: {new Date(createdAt).toLocaleDateString()}
        </ColoredDetails>
      </ColoredBox>
    </Container>
  );
};
