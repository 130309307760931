import { connect } from 'react-redux';
import { useState, useEffect } from 'react';

import classes from './BedCount.module.scss';
import { BedCountEdit } from './BedCountEdit';
import { SliderOption } from './SliderOption/SliderOption';
import { SearchCombiner } from '../../../../SearchCombiner';
import { SearchValidator } from '../../../../SearchValidator';
import { CriteriaOptionsSubtitle } from '../components/CriteriaOptionsSubtitle/CriteriaOptionsSubtitle';
import { CRITERIAS } from '../../../Criterias';
import { useCriteriaIsOpen } from '../../../CriteriaIsOpenProvider';
import { CriteriaAccordion } from '../components/CriteriaAccordion';

const info =
  'Data is pulled directly from PropertyModal records for the subject and defaults to limit comps to only matching ("same") bed counts. The matches can be adjusted by sliding the orange ball and/or clicking on the scale for a broader search.';
const BedCountComponent = ({ searchCriteria, className, onChange }) => {
  const [beds, setBeds] = useState(null);
  const [bedsDefault, setBedsDefault] = useState(null);
  const [bedsRange, setBedsRange] = useState([0, 0]);
  const [bedCountOptions, setBedCountOptions] = useState(null);

  const [isOpen] = useCriteriaIsOpen(CRITERIAS.BEDS);

  const validate = () => {
    const min = Number.parseInt(beds) + Math.min(...bedsRange);
    const max = Number.parseInt(beds) + Math.max(...bedsRange);
    return !Number.isNaN(min) && !Number.isNaN(max);
  };
  const onCombine = () => ({
    beds,
    bedsInit: bedsDefault,
    bedsRange,
  });
  useEffect(() => {
    setBeds(isNaN(parseInt(searchCriteria?.beds)) ? beds : searchCriteria.beds);
    setBedsDefault(searchCriteria.bedsInit || searchCriteria.beds || beds);
    setBedsRange(searchCriteria.bedsRange || bedsRange);
  }, []);

  useEffect(() => {
    SearchValidator.subscribe('beds', validate);
    SearchCombiner.add('beds', onCombine);
    if (beds <= 1) {
      setBedCountOptions([
        { label: beds, value: 0 },
        { label: beds + 1, value: 1 },
        { label: beds + 2, value: 2 },
      ]);
    } else if (beds === 2) {
      setBedCountOptions([
        { label: beds - 1, value: -1 },
        { label: beds, value: 0 },
        { label: beds + 1, value: 1 },
        { label: beds + 2, value: 2 },
      ]);
    } else if (beds > 2) {
      setBedCountOptions([
        { label: beds - 2, value: -2 },
        { label: beds - 1, value: -1 },
        { label: beds, value: 0 },
        { label: beds + 1, value: 1 },
        { label: beds + 2, value: 2 },
      ]);
    }
    onChange(SearchCombiner.combine());

    return () => {
      SearchValidator.unsubscribe('beds');
      SearchCombiner.remove('beds');
    };
  }, [beds, bedsRange]);

  if (isNaN(parseInt(beds))) {
    return null;
  }
  return (
    <CriteriaAccordion title='BED COUNT' info={info} isOpen={isOpen}>
      <BedCountEdit defaultValue={bedsDefault} subject={beds} onSubjectChange={setBeds} />
      <CriteriaOptionsSubtitle />
      <SliderOption
        initValue={bedsRange}
        options={bedCountOptions}
        selected={bedsRange}
        changed={setBedsRange}
        className={classes.slider}
      />
    </CriteriaAccordion>
  );
};
const stateToProps = ({ search }) => ({
  searchCriteria: search.searchCriteria,
});
export const BedCount = connect(stateToProps)(BedCountComponent);
