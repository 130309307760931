import React, { useMemo } from 'react';

import {
  ModernCoverContainer,
  ImagePreview,
  InfoContainer,
  TopContainer,
} from './CoverContentDivided.styles';
import { CoverLogos } from '../CoverLogos/CoverLogos.component';
import { useSlide } from '../../../../../../providers/SlideProvider';
import { ICoverContentDividedProps } from './CoverContentDivided.component';
import { Report } from '../../../../../../../../pages/PresentationCreate/dash';
import { PropertyInfo } from '../../components/PropertyInfo/PropertyInfo.component';

export const CoverContentDividedRegular: React.FC<ICoverContentDividedProps> = ({
  divider,
  isReversed,
}) => {
  const { addressShort, beds, baths, size, address } = Report.data.subject.useSubjectInfo();
  const slide = useSlide();

  const displayImage = slide?.data?.displayImage;
  const image = slide?.data?.image;

  const noImageLogoPosition = !displayImage && !isReversed ? 'flex-end' : 'center';
  const addressLong = useMemo(() => {
    if (!addressShort) return '';

    return `${addressShort} | ${address.city}, ${address.state} ${address.zip}`;
  }, [address, addressShort]);

  return (
    <ModernCoverContainer>
      <TopContainer>
        {displayImage ? (
          <ImagePreview style={{ backgroundImage: `url("${image}")` }} />
        ) : (
          <PropertyInfo addressShort={addressShort} beds={beds} baths={baths} size={size} />
        )}
      </TopContainer>
      {divider}
      <InfoContainer isReversed={!displayImage ? isReversed : false}>
        {displayImage && (
          <PropertyInfo
            addressShort={addressLong}
            beds={beds}
            baths={baths}
            size={size}
            withImage
          />
        )}
        <CoverLogos
          displayImage={displayImage}
          logoContainerStyles={{
            justifyContent: displayImage ? 'flex-start' : noImageLogoPosition,
          }}
        />
      </InfoContainer>
    </ModernCoverContainer>
  );
};
